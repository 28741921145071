import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import SimpleTable from "components/Table/Table";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Blade template tags in Laravel";
  const dateStamp = "20150208";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Laravel use cascading template engine Blade. It has interesting tags I should write down to remember before I
          completely forgot what they mean.
        </p>
        <p>Tags as below,</p>
        <Highlight key={0} language="Java">
          {`@section
@show
@stop
@parent
@yield`}
        </Highlight>
        <p>Here’s the full list</p>
        <SimpleTable
          heads={["Tag", "Note"]}
          rows={[
            [<code key={0}>{`{{ $var }}`}</code>, "Echo content"],
            [<code key={0}>{`{{ $var or 'default' }}`}</code>, "Echo content with a default value"],
            [<code key={0}>{`{{{ $var }}}`}</code>, "Echo escaped content"],
            [<code key={0}>{`{{-- Comment --}}`}</code>, "A Blade comment"],
            [<code key={0}>{`@extends('layout')`}</code>, "Extends a template with a layout"],
            [<code key={0}>{`@if(condition)`}</code>, "Starts an if block"],
            [<code key={0}>{`@else`}</code>, "Starts an else block"],
            [<code key={0}>{`@elseif(condition)`}</code>, "Start a elseif block"],
            [<code key={0}>{`@endif`}</code>, "Ends a if block"],
            [<code key={0}>{`@foreach($list as $key => $val)`}</code>, "Starts a foreach block"],
            [<code key={0}>{`@endforeach`}</code>, "Ends a foreach block"],
            [<code key={0}>{`@for($i = 0; $i < 10; $i++)`}</code>, "Starts a for block"],
            [<code key={0}>{`@endfor`}</code>, "Ends a for block"],
            [<code key={0}>{`@while(condition)`}</code>, "Starts a while block"],
            [<code key={0}>{`@endwhile`}</code>, "Ends a while block"],
            [<code key={0}>{`@unless(condition)`}</code>, "Starts an unless block"],
            [<code key={0}>{`@endunless`}</code>, "Ends an unless block"],
            [<code key={0}>{`@include(file)`}</code>, "Includes another template"],
            [
              <code key={0}>{`@include(file, ['var' => $val,...])`}</code>,
              "Includes a template, passing new variables.",
            ],
            [<code key={0}>{`@each('file',$list,'item')`}</code>, "Renders a template on a collection"],
            [
              <code key={0}>{`@each('file',$list,'item','empty')`}</code>,
              "Renders a template on a collection or a different template if collection is empty.",
            ],
            [<code key={0}>{`@yield('section')`}</code>, "Yields content of a section."],
            [<code key={0}>{`@show`}</code>, "Ends section and yields its content"],
            [<code key={0}>{`@lang('message')`}</code>, "Outputs message from translation table"],
            [<code key={0}>{`@choice('message', $count)`}</code>, "Outputs message with language pluralization"],
            [<code key={0}>{`@section('name')`}</code>, "Starts a section"],
            [<code key={0}>{`@stop`}</code>, "Ends section"],
            [<code key={0}>{`@endsection`}</code>, "Ends section"],
            [<code key={0}>{`@append`}</code>, "Ends section and appends it to existing of section of same name"],
            [<code key={0}>{`@overwrite`}</code>, "Ends section, overwriting previous section of same name"],
          ]}
        />
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
