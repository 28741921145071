import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import SimpleTable from "components/Table/Table";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "SMACSS architecture reference for writing CSS";
  const dateStamp = "20171117";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          The concept is to keep CSS more organized and more structured, leading to code that is easier to build and
          easier to maintain. SMACSS is one of the reference guides about CSS naming rules and conventions.
        </p>
        <p>To begin with, there are 5 major categories from SMACSS point of view in CSS functionalities.</p>
        <ol>
          <li>Base – fundamental default element display features, like body, form, input or a</li>
          <li>Layout – decide how page is divided into sections</li>
          <li>Module – reusable parts like modal, sidebar, etc</li>
          <li>State – define how module will change based on different context (different views/devices)</li>
          <li>Theme – layer of theming, not necessary</li>
        </ol>
        <p>For each of these categories, there are rules associated with it. Bear these simple minds with it,</p>
        <ol>
          <li>
            Base rule is default style, no need to use <code>!important</code>,&nbsp;and Reset CSS is helpful
          </li>
          <li>
            Layout has major &amp; minor. Major layout are mostly shared across whole site, recommend using ID selector.
          </li>
          <li>
            Modules should avoid element selectors, and use class instead. And sub-classing is the key to increase
            portability on modules
          </li>
          <li>
            State should not only apply to layout/module, but also indicate javascript dependency.{" "}
            <code>!important</code> is recommended at this level.
          </li>
          <li>State change can be done in 3 ways, class name, pseudo-class and media query</li>
        </ol>
        <p>Having said all these basic category concept and rules, there are actually some thinking go beyond.</p>
        <p>
          <strong>Minimize the CSS depth –&nbsp;</strong>for better maintenance and readability/portability
        </p>
        <p>
          <strong>CSS Evaluation flow has below facts, thus recommendation based on the facts,</strong>
        </p>
        <SimpleTable
          rows={[
            [
              "Facts",
              <ol key={1}>
                <li>
                  Style of an element is evaluated on the element creation (e.g. document rendered during transmission)
                </li>
                <li>b. CSS is evaluated from right to left</li>
              </ol>,
            ],
            [
              "Recommendations",
              <ol key={1}>
                <li>Use child selectors</li>
                <li>Avoid tag selectors for common elements</li>
                <li>Use class names as the right-most selector</li>
              </ol>,
            ],
          ]}
        />
        <p>
          <strong>Using CSS Preprocessors are encouraged&nbsp;</strong>(LESS SASS Stylus)
        </p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
