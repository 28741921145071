import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import SimpleTable from "components/Table/Table";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function A03linuxmem(props) {
  const classes = useStyles();

  const title = "Linux memory management";
  const dateStamp = "20120905";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Study about memory management. <br />
          Memory control is critical for running the OS.
          <br />4 useful tools to investigate the memory status in Linux system,
        </p>
        <SimpleTable
          heads={["Command", "Note"]}
          rows={[
            [
              <code key={0} language="Bash">
                cat /proc/meminfo
              </code>,
              "This command shows the current system memory map status. With a simple list, it describes the current situation of the machine.",
            ],
            [
              <code key={0} language="Bash">
                free
              </code>,
              "This is a simple command. Noted only the used for Cache is OS currently occupying.",
            ],
            [
              <div key={0}>
                <code language="Bash">ps aux</code>
                {" combine with "}
                <code language="Bash">pmap</code>
              </div>,
              <div key={1}>
                As the previous blog page mentioned, it shows all the process of current machine. Useful for PID!
                <Highlight language="Bash">
                  pmap -x 25926 #-x option can be used to provide information about the memory allocation and mapping
                  types per mapping
                </Highlight>
                <Highlight language="Bash">
                  pmap -xs 25926 #-s option can be used to display the hardware translation page sizes for each portion
                  of the address space
                </Highlight>
              </div>,
            ],
            [
              <div key={0}>
                <code language="Bash">top</code>
                {" & "}
                <code language="Bash">htop</code>
              </div>,
              "This is the big star! Useful tips: c, f, r, t",
            ],
          ]}
        />
      </div>
    </div>
  );
}

A03linuxmem.propTypes = {
  link: PropTypes.string,
};
