import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import s3Diagram from "assets/img/s3serverless.png";
import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Migration Over to S3";
  const dateStamp = "20200713";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>I finally made it! Moving to serverless stack for my site!</p>
        <p>
          Since my 3 years reserve instance is about to expire, now is about the time for utilizing the serverless
          architecture I have been practicing, and also lowering down the cost if I do not have to pay for a running
          instance. And here is how I did it in an overview,
        </p>
        <img src={s3Diagram} alt="s3 serverless diagram" />
        <p>From application-level details, essentially it is React Single-Page-App. And here is how we setup routes,</p>
        <Highlight language="Javascript">
          {`<Switch>
  <Route exact path={path}>
    {routes.map((r, i) => (
      <r.page key={'c'+i} link={\`/\${r.link}\`} />
    ))}
  </Route>
  {routes.map((r, i) => (
    <Route key={'a'+i} path={\`/\${r.link}\`}>
      <r.page link={\`/\${r.link}\`} />
    </Route>
  ))}
</Switch>`}
        </Highlight>
        <p>
          From the infrastructure perspective, its the cliche of using public S3 bucket as web content hosting, having
          CloudFront on top of it to enable hooking up with public Certificate issue by ACM, and finally have Route53 to
          hook up CloudFront endpoint.
        </p>
        <p>
          <b>Important note:</b> remember using A / AAAA records on an Alias hook up.
        </p>
        <p>Finally have the script synced with the build directory:</p>
        <Highlight language="Bash">{`#!/bin/bash
echo 'Start syncing ...'
aws s3 sync ./build/ s3://hansw/ --delete
echo '... Done!'`}</Highlight>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
