import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/hwblog/views/landingPageSections/configStyle.js";

const useStyles = makeStyles(styles);

export default function P04XmonadConfig(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12}>
          <h2 className={classes.title}>
            <Link to={props.link}>My Xmonad Config</Link>
          </h2>
          <h5 className={classes.description}>My GNU Screen config Written in ~/.screenrc</h5>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.snippet}>
        <GridItem xs={12}>
          <Highlight language="Python">
            {`import XMonad
import XMonad.Hooks.DynamicLog
import XMonad.Hooks.ManageDocks
import XMonad.Util.Run(spawnPipe)
import XMonad.Util.EZConfig(additionalKeys)
import XMonad.Layout.Spacing    
import System.IO
 
myWorkspaces = ["1:web","2:sublime","3:staging","4:SEO","5:work","6:sync","7","8","9"]
 
myLayout = tiled ||| Mirror tiled ||| Full
  where
    tiled = spacing 2 $ Tall nmaster delta ratio
    nmaster = 1
    ratio = 1/2
    delta = 3/100
 
myManageHook = composeAll
    [ className =? "Gimp" --> doFloat
    ]
  
main = do
    xmproc <- spawnPipe "/usr/bin/xmobar /home/hans/.xmobarrc"
    xmonad $ defaultConfig
        { manageHook = manageDocks <+> myManageHook
        , layoutHook = avoidStruts $ myLayout
        , borderWidth = 2
        , normalBorderColor = "#6b4517"
        , focusedBorderColor = "#0db7e1"
        , logHook = dynamicLogWithPP xmobarPP
                        { ppOutput = hPutStrLn xmproc
                        , ppTitle = xmobarColor "green" "" . shorten 50
                        , ppLayout = const ""
                        }
        , modMask = mod4Mask
        , workspaces = myWorkspaces
        } <code>additionalKeys</code>
        [ ((mod4Mask .|. controlMask, xK_l), spawn "xscreensaver-command -lock")
        , ((0, xK_Print), spawn "sleep 0.2; scrot -s")
        ]`}
          </Highlight>
          <h5 className={classes.description}>And never forget the xmobar Config .xmobarrc</h5>
          <Highlight language="Bash">
            {`Config { font = "xft:Bitstream Vera Sans Mono:size=9:bold:antialias=true"
       , bgColor = "black"
       , fgColor = "grey"
       , position = TopW R 100
       , lowerOnStart = True
       , commands = [ Run Network "wlan0" ["-L","0","-H","32","--normal","green","--high","red"] 10
                    , Run Cpu ["-L","3","-H","50","--normal","green","--high","red"] 10
                    , Run Memory ["-t","Mem: <usedratio>%"] 10
                    , Run Swap [ ] 10
                    , Run Date "%a %b %_d %Y %H:%M:%S" "date" 10
                    , Run StdinReader
                    ]
        , sepChar = "%"
        , alignSep = "}{"
        , template = "%StdinReader% }{ %cpu% | %memory% * %swap% | %wlan0% | <fc=#ee9a00>%date%</fc>"
}`}
          </Highlight>
        </GridItem>
      </GridContainer>
    </div>
  );
}

P04XmonadConfig.propTypes = {
  link: PropTypes.string,
};
