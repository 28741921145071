import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Difference between Diffie-Hellman Key Exchange and RSA";
  const dateStamp = "2021-11-18";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>RSA and the Diffie-Hellman Key Exchange are the two most popular encryption algorithms. In brief,</p>
        <ul>
          <li>
            <b>Diffie Hellman</b> approach generates a public and private key on both sides of the transaction, but only
            shares the public key.
          </li>
          <li>
            <b>RSA</b> algorithm can be used for signing digital signatures as well as symmetric key exchange, but it
            does require the exchange of a public key beforehand.
          </li>
        </ul>
        <Alert severity="info">
          <AlertTitle>Tips</AlertTitle>
          In general, public key encryption, or asymmetric encryption, is about <strong>
            10,000 times slower
          </strong>{" "}
          than private key encryption.
          {`This is because of asymmetric encryption's creation and exchange of the two keys versus the single one in private or symmetric encryption.`}
        </Alert>
        <h5>The Diffie-Hellman Key Exchange details</h5>
        <p>
          Diffie–Hellman key exchange establishes a shared secret between two parties that can be used for secret
          communication for exchanging data over a public network and actually uses public-key techniques to allow the
          exchange of a private encryption key.
        </p>
        <p>
          In an simplified example with small positive integers, Alice and Bob wants to share encryption keys, they
          would follow below steps,
        </p>
        <ol>
          <li>
            Both of them agree on two positive integers, a prime number, and a generator.
            <ul>
              <li>
                <i>A generator</i> is a number that, when raised to positive whole-number powers less than the prime
                number, never produces the same result for any two such whole numbers.
              </li>
              <li>assume that Alice will use the prime number 17 and Bob the generator 3</li>
            </ul>
          </li>
          <li>
            Alice selects a private random number, say 15, and calculates 3**15%17 which equals 6 and sends the result
            publicly to Bob.
          </li>
          <li>
            Then Bob selects his private random number, say 13, calculates 3**13%17 and sends the result (which is 12)
            publicly to Alice
          </li>
          <li>
            <b>The heart of the trick is the following computation.</b> Alice takes Bob’s public result (=12) and
            calculates 12**15%17. The result (=10) is their shared secret key.
          </li>
          <li>
            On the other hand, Bob takes Alice’s public result (=6) and calculates 6**13%17 which results again to the
            same shared secret as 10.
          </li>
          <li>
            Now Alice and Bob can communicate using the symmetric algorithm of their choice and the shared secret key
            (=10), which was never transmitted over the insecure circuit.
          </li>
        </ol>
        <p>
          If a third party was listening to the exchange, it would be computationally difficult for this party to
          determine the secret key. In fact, when using large numbers, this action is computationally expensive for
          modern supercomputers to do in a reasonable amount of time.
        </p>
        <h5>RSA in quick glance</h5>
        <p>
          {`RSA as a popular Public-key cryptography, also known as asymmetric cryptography, uses two different but mathematically linked keys, one public and one private.
          The public key can be shared with everyone, whereas the private key must be kept secret.
          In RSA cryptography, both the public and the private keys can encrypt a message; the opposite key from the one used to encrypt a message is used to decrypt it.`}
        </p>
        <p>
          {`RSA derives its security from the difficulty of factoring large integers that are the product of two large prime numbers.
          Multiplying these two numbers is easy, but determining the original prime numbers from the total, that’s factoring, is considered infeasible due to the time it would take even using today’s super computers.`}
        </p>
        <h5><b>The difference</b></h5>
        <p>Both RSA and Diffie-Hellman are secure key exchange algorithm recommended by mathematicians and cryptographers with correct implementation, such as minimal of 1024 bit keys length.</p>
        <p>
          {`The nature of the Diffie-Hellman key exchange, however, makes it susceptible to man-in-the-middle (MITM) attacks,
          since it doesn't authenticate either party involved in the exchange.
          The MITM maneuver can also create a key pair and spoof messages between the two parties, who think they're both communicating with each other.
          This is why Diffie-Hellman is used in combination with an additional authentication method, generally digital signatures.`}
        </p>
        <p>RSA algorithm can be used for signing digital signatures as well as symmetric key exchange, but it does require the exchange of a public key beforehand. However, recent research has demonstrated that even 2048-bits long RSA keys can be effectively downgraded via either man-in-the-browser or padding oracle attacks. The report suggests that the safest countermeasure is to deprecate the RSA key exchange and switch to (Elliptic Curve) Diffie-Hellman key exchanges.</p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
