import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "PHP 7.0 preview";
  const dateStamp = "20151008";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          It is announced that PHP 7.0 is scheduled for release at later of 2015. Some of the key improvements are
          listed as below,
        </p>
        <ul>
          <li>Improved performance: PHP 7 is up to twice as fast as PHP 5.6</li>
          <li>Consistent 64-bit support</li>
          <li>Many fatal errors are now Exceptions</li>
          <li>Removal of old and unsupported SAPIs and extensions</li>
          <li>The null coalescing operator (??)</li>
          <li>Combined comparison Operator (&lt;=&gt;)</li>
          <li>Return Type Declarations</li>
          <li>Scalar Type Declarations</li>
          <li>Anonymous Classes</li>
        </ul>
        <p>As for the exceptions, structured as below,</p>
        <Highlight key={0} language="Bash">
          {`\\Throwable
├── \\Exception (implements \\Throwable)
│   ├── \\LogicException (extends \\Exception)
│   │   ├── \\BadFunctionCallException (extends \\LogicException)
│   │   │   └── \\BadMethodCallException (extends \\BadFunctionCallException)
│   │   ├── \\DomainException (extends \\LogicException)
│   │   ├── \\InvalidArgumentException (extends \\LogicException)
│   │   ├── \\LengthException (extends \\LogicException)
│   │   └── \\OutOfRangeException (extends \\LogicException)
│   └── \\RuntimeException (extends \\Exception)
│       ├── \\OutOfBoundsException (extends \\RuntimeException)
│       ├── \\OverflowException (extends \\RuntimeException)
│       ├── \\RangeException (extends \\RuntimeException)
│       ├── \\UnderflowException (extends \\RuntimeException)
│       └── \\UnexpectedValueException (extends \\RuntimeException)
└── \\Error (implements \\Throwable)
    ├── \\AssertionError (extends \\Error)
    ├── \\ParseError (extends \\Error)
    └── \\TypeError (extends \\Error)`}
        </Highlight>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
