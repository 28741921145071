import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Study about Splunk";
  const dateStamp = "20171115";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Since I know ELK as a starting point, getting to know splunk is mostly a fun experience. But I did spend fair
          bit of time to compare these 2 products. So to start with,
        </p>
        <ol>
          <li>Splunk is Commercial Paid product while ELK is open source.</li>
          <li>
            Splunk is expensive in license while ELK claimed to be free, but setup requires time-consuming tasks and
            hardware cost is also a potential concern
          </li>
          <li>Splunk is usually on-prem while ELK can be anywhere.</li>
          <li>Both log results will be as good as the data which got sent in</li>
          <li>
            Splunk provider smoother way to parse data by defining the data fields after data is already in the system.
            However, ELK needs data fields defined before the data is in.
          </li>
        </ol>
        <h5>Some typical splunk usage</h5>
        <Highlight language="Bash">
          {`... | rex field=savedsearch_id "(?<user>w+);(?<app>w+);(?<SavedSearchName>w+)"`}
        </Highlight>
        <Highlight language="Bash">
          {`... | eval n=mvindex(multifield, 2)"`}
        </Highlight>
        <p>Here for FYI useful links,</p>
        <p>
          <a
            href="https://docs.splunk.com/Documentation/SCS/current/SearchReference/RexCommandExamples"
            target="_blank"
            rel="noopener noreferrer"
          >
            rex command examples
          </a>
        </p>
        <p>
          <a
            href="https://docs.splunk.com/Documentation/SCS/current/SearchReference/EvalCommandExamples"
            target="_blank"
            rel="noopener noreferrer"
          >
            eval command examples
          </a>
        </p>
        <p>
          <a
            href="https://docs.splunk.com/Documentation/Splunk/8.0.3/SearchTutorial/Createnewdashboard"
            target="_blank"
            rel="noopener noreferrer"
          >
            Create splunk dashboard
          </a>
        </p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
