import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import SimpleTable from "components/Table/Table";
import Muted from "components/Typography/Muted.js";

import cubicBezier from "assets/img/cubicbezier.png";
import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "About cubit bezier function And animation in CSS";
  const dateStamp = "20180703";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Animation in CSS is the key to create engaging UI. To start with, here is the list of animation properties CSS
          supports.
        </p>
        <SimpleTable
          heads={["Command", "Note"]}
          rows={[
            [<code key={0}>animation-delay</code>, "Specifies a delay for the start of an animation"],
            [
              <code key={0}>animation-direction</code>,
              "Specifies whether an animation should be played forwards, backwards or in alternate cycles",
            ],
            [
              <code key={0}>animation-duration</code>,
              "Specifies how long time an animation should take to complete one cycle",
            ],
            [
              <code key={0}>animation-fill-mode</code>,
              "Specifies a style for the element when the animation is not playing (before it starts, after it ends, or both)",
            ],
            [
              <code key={0}>animation-iteration-count</code>,
              "Specifies the number of times an animation should be played",
            ],
            [<code key={0}>animation-name</code>, "Specifies the name of the @keyframes animation"],
            [<code key={0}>animation-play-state</code>, "Specifies whether the animation is running or paused"],
            [<code key={0}>animation-timing-function</code>, "Specifies the speed curve of the animation"],
          ]}
        />
        <p>Normally how to setup animation in CSS is by @keyframes.</p>
        <p>
          A typical example would be in{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/daneden/animate.css/blob/master/animate.css"
          >
            AnimateCSS
          </a>{" "}
          git repo.
        </p>
        <p>
          But today I will focus on one particular property named animation-timing-function, and its value cubic-bezier.
        </p>
        <p>
          Cubic-bezier is a maths concept which lays a curve on 1×1 area axes. The curve has 4 points in control,
          [(0,0), (x1, y1), (x2, y2), (1,1)].
        </p>
        <p>
          The cubic-bezier only took 2 middle points’ coordinates to determine the curves. To illustrate what it does, a
          graph from{" "}
          <a target="_blank" rel="noopener noreferrer" href="http://cubic-bezier.com/">
            here
          </a>{" "}
          clarifies the solution.
        </p>
        <p>
          <img src={cubicBezier} alt="Cubit Bezier Curve" />
        </p>
        <p>So the above line translates to cubic-bezier(.27,1.26,.66,-0.36).</p>
        <p>
          Just bear in mind this is only a speed function of timing, reverse on Y axis only means traverse back into the
          animation process. So this way it can easily achieve the “bouncy” simulation.
        </p>
        <p>
          Lastly, another interesting site to visit is&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://easings.net/">
            https://easings.net/
          </a>
          . It has the nice illustration of how most curves behave with an buildin animation demo.
        </p>
        <p>Happy animating!</p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
