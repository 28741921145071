import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Quora Read about Job Difficulty";
  const dateStamp = "20150810";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Today I came across a question about Job Difficulty in Quora.{" "}
          <a
            href="http://www.quora.com/I-just-got-a-new-job-as-a-developer-Are-these-normal-working-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ref
          </a>
        </p>
        <p>One line here hit me the most,</p>
        <blockquote>
          <p>
            I believe comments are an abomination – if you need to comment the code, its overly complex and the code
            should be rewritten to be clearer
          </p>
        </blockquote>
        <p>I can’t agree more.</p>
        <p>
          Once in my early days of coding, I believe code should be properly commented with reasonably “human language”
          explanation.
        </p>
        <p>
          However, when the coding progress, it really comes to a question of “why will I comment this well structured,
          perfectly cleared and beautifully styled code?”
        </p>
        <p>
          Well, most of the times, daily tasks involves clear, straight-forward meaning of code which doesn’t requires
          too much thinking on algorithms, or sophisticated calculations. If you are writing something too complicated
          for a production env purpose, you are either over thinking the objective, or you just doing it quite
          inefficiently. Most of commonly used functionalities, (like jquery-file-uploader, or node cheerio, or node
          mongoose), try to find a library, and improve from there externally.
        </p>
        <p>
          In summary, comment are only used for pieces of code where GEEKs wrote in dodgy “Hackthon” project where it
          can cut-corners, and improve trivial performance in exchange of hard reading style, in which case I would
          highly doubt there will be a comment.
        </p>
        <p>
          Normal coding should always follow best practice, structure the code clearly (MVC, middleware, etc). Naming
          properly with intention of the code objectives. and styled consistently throughout the code. (Which means
          always space/tabs, and tabs is of 4 spaces wide).
        </p>
        <p>Thanks for reading, keep up to date with resume &amp; coding.</p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
