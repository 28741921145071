import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function A02psaux(props) {
  const classes = useStyles();

  const dateStamp = "20120905";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>Study about ps aux</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>The kill command is used in conjunction with a process ID (PID),</p>
        <p>The killall command is used in conjunction with command names.</p>
        <p>For command names and PIDs, the ps aux comes into use.</p>
        <Highlight language="Bash">{`$ ps aux`}</Highlight>
        <p>Result will look like.</p>
        <Highlight language="Bash">{` USER PID %CPU %MEM VSZ RSS TTY STAT START TIME COMMAND
 
 root 231 0.0 0.0 0 0 ? D Apr30 0:16 [jbd2/sda5-8]
 root 232 0.0 0.0 0 0 ? S&lt; Apr30 0:00 [ext4-dio-unwrit]
 hans 312 0.0 0.0 0 0 ? ZN 13:29 0:00 [debconf-communi]
 syslog 644 0.0 0.0 28940 1524 ? Sl Apr30 0:05 rsyslogd -c5`}</Highlight>
        <p>Descriptions as below</p>
        <Highlight language="Bash">{`USER = user owning the process
PID = process ID of the process
%CPU = It is the CPU time used divided by the time the process has been running.
%MEM = ratio of the process’s resident set size to the physical memory on the machine
VSZ = virtual memory usage of entire process
RSS = resident set size, the non-swapped physical memory that a task has used
TTY = controlling tty (terminal)
STAT = multi-character process state
START = starting time or date of the process
TIME = cumulative CPU time
COMMAND = command with all its arguments`}</Highlight>
        <p>Using</p>
        <Highlight language="Bash">{`$ ps aux | grep sublime`}</Highlight>
        <p>Should do the work to find the suitable process information to kill.</p>
      </div>
    </div>
  );
}

A02psaux.propTypes = {
  link: PropTypes.string,
};
