import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "PHP version difference";
  const dateStamp = "20150220";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>PHP version difference is something that likely to be neglected for most “script writers”.</p>
        <p>
          Especially when upgrading system, paying attention to version difference is extremely important when you
          looking after the code.
        </p>
        <p>Here are some brief points that in my opinion should be remembered all the time.</p>
        <ol>
          <li>
            <b>From PHP5.2 to PHP5.3</b>
            (Most php version starts from PHP5.3 now. <i> If not, urgently upgrade!!</i>)<p></p>
            <ul>
              <li>
                Since PHP5.3 has namespaces, this is critical important for modern PHP development. Needed for most of
                modern frameworks
              </li>
              <li>PHP5.3 support native closures</li>
              <li>
                <code>goto</code> is enabled
              </li>
              <li>
                If version PHP &gt;= 5.3.7, password_compat lib can be used for encrypting passwords by BCRYPT
                implementation.{" "}
                <a title="Ref" href="http://php.net/security/crypt_blowfish.php" target="_blank" rel="noopener noreferrer">
                  Ref Here
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong>From PHP5.3 to PHP5.4</strong>
            (This is the major upgrade, several key points needs to be remembered)<p></p>
            <ul>
              <li>
                <strong>MAGIC QUOTES</strong> is removed. The evil is gone.
              </li>
              <li>
                Safe Mode is not supported, which used to check the ownership of script matches the owner of the file to
                be operated on.
              </li>
              <li>
                Closure support <code>$this</code>
              </li>
              <li>
                Support build-in Test only server such as <code>$ php -S localhost:8000 -t foo/</code>
              </li>
            </ul>
          </li>
          <li>
            <strong>From PHP5.4 to PHP5.5</strong>
            <ul>
              <li>Windows XP support dropped</li>
              <li>
                <code>finally</code> block added
              </li>
              <li>
                New password Hahsing API as mentioned earlier. (<code>password_hash()</code>)
              </li>
            </ul>
          </li>
          <li>
            <strong>From PHP5.5 to PHP5.6</strong>
            <ul>
              <li>Exponentiation Operator ** is added</li>
              <li>
                <code>json_decode</code> will see non-lowercased variants of <em>true</em>, <em>false </em> and{" "}
                <em>null </em>as invalid.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
