import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Outline about RESTful API system";
  const dateStamp = "20151222";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Recently I have been doing RESTful API programming a lot. My understanding about RESTful evolves from
          “HTTP-Only” request to something more. Below is what I get from recent thoughts.
        </p>
        <ol>
          <li>
            REST is <strong>Representational State Transfer</strong>
            <p></p>
            <p>
              It is and only is a design framework for making network communication “web-like.” No specific protocol was
              proposed, and its main focus was on the communication part. Several questions should be considered during
              the developement,{" "}
            </p>
            <ul>
              <li>What are the components of the system?</li>
              <li>How should they communicate with each other?</li>
              <li>How do we ensure we can swap out different parts of the system at any time?</li>
              <li>How can the system be scaled up to serve billions of users?</li>
            </ul>
          </li>
          <li>
            <strong>Client-server</strong>
            <p></p>
            <p>
              This is basic system flow of any REST system looks like. It has to be 1-to-1 flow with a centralized
              resource server. And any other parties who wants to interact with the resources
            </p>
          </li>
          <li>
            <strong>Stateless</strong>
            <p></p>
            <p>
              Most important principle in REST, which means “
              <em>
                <strong>Each request is treated as a standalone</strong>
              </em>
              “. In detail, when a client is not interacting with the server, the server has no idea of its existence.
              The server also does not keep a record of past requests.
            </p>
          </li>
          <li>
            <strong>Stable identification of resources</strong>
            <p></p>
            <p>
              Each resource must be uniquely identified by a stable identifier. A “stable” identifier means that it does
              not change across interactions, and it does not change even when the state of the resource changes.
            </p>
          </li>
          <li>
            <strong>Manipulation of resources through representations</strong>
            <p></p>
            <p>
              Client manipulates resources through sending representations to the server–usually a JSON object
              containing the content that it would like to add, delete, or modify. In WEB case, it sends an HTTP POST or
              PUT request with the content to the server. The server sends back a response indicating whether operation
              was successful.
            </p>
          </li>
          <li>
            <strong>Self-descriptive messages</strong>
            <p></p>
            <p>
              A self-descriptive message is one that contains all the information that the recipient needs to understand
              it. In WEB case, for example, request like below
            </p>
            <Highlight language="YAML">{`GET / HTTP/1.1
Host: www.example.com`}</Highlight>
            <p>
              This message is self-descriptive because it told the server what HTTP method was used, and the protocol
              that was used (HTTP 1.1).
              <br />
              <br />
              The server may send back a response like this:
            </p>
            <Highlight language="HTML">{`HTTP/1.1 200 OK
Content-Type: text/html
 
<!DOCTYPE html>
<html>
  <head>
    <title>Home Page</title>
  </head>
  </body>
    <div>Hello World!</div>
  </body>
</html>`}</Highlight>
            <p>
              This message is self-descriptive because it told the client how it needs to interpret the message body, by
              indicating that <code>Content-type</code> was <code>text/html</code>.
            </p>
          </li>
          <li>
            <strong>Hypermedia</strong>
            <p></p>
            <p>
              Hypermedia is a fancy word for data sent from the server to the client that contains information about
              what the client can do next–in other words, what further requests it can make. (Optional)
            </p>
          </li>
          <li>
            <strong>Cache</strong> refers to the constraint that server responses should be labelled as either cacheable
            or non-cacheable. It should contains in the “self-descriptive message” described mentioned above.
          </li>
          <li>
            <strong>Layered system</strong> refers to the fact that there can be more components than just servers and
            clients. For example, proxy as load balancer, or security checker, authenticator, gateway.
          </li>
          <li>
            <strong>Code on demand</strong> works as the ability for a server to send executable code to the client, for
            example, HTTP server send &lt;script&gt; tag to client to execute locally.
          </li>
        </ol>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
