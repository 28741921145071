import React from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import classNames from "classnames";

import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import routes from "./routes";

import bgImage from "assets/img/landing-bg.jpg";
import styles from "assets/jss/hwblog/views/landingPage.js";

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { path, url } = useRouteMatch();
  return (
    <div>
      <Header
        color="transparent"
        brand="Hans Useful Config"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={bgImage}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Keep foolish, Keep hungry</h1>
              <h4>Handy Configuration for multiple tools ready for Sharing</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Switch>
            <Route exact path={path}>
              {routes.map((r, i) => (
                <r.page key={i} link={`${url}/${r.link}`} />
              ))}
            </Route>
            {routes.map((r, i) => (
              <Route key={i} path={`${path}/${r.link}`}>
                <r.page link={`${url}/${r.link}`} />
              </Route>
            ))}
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
}
