import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import prelight from "assets/img/prelight.png";
import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "CORS and cross-origin HTTP requests";
  const dateStamp = "20170208";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          It seems that you have already known quite a few about HTTP request and CORS. But there is always something to
          learn when you look closer
        </p>
        <p>
          <strong>CORS</strong> is short for Cross-Origin Resource Sharing. As the word shows, it gives web servers
          cross-domain access controls over either static or dynamic data. For static data sharing, its typical type of
          how CDN works. In essence, it adds new HTTP headers that allow servers to describe the set of origins that are
          permitted to read that information using a web browser. This is the basic background knowledge.
        </p>
        <p>
          An interesting note about these requests is there are types of “preflighted” ones. The cause of this is many
          HTTP methods could have side-effects on server’s data. In order to prevent actual data impact, a more elegant
          solution would be mandating the browser “preflight” the request to get “approval” from the server first by an
          HTTP OPTIONS request, then sending the actual request with actual HTTP request.
        </p>
        <p>
          There are conditions to trigger a CORS preflight, falling any of below 3 conditions will need a preflighted
          request. Otherwise, a simple request will be sufficient to achieve the goal.
        </p>
        <ol>
          <li>
            Request uses methods other than following<p></p>
            <ul>
              <li>GET</li>
              <li>POST</li>
              <li>HEAD</li>
            </ul>
          </li>
          <li>
            Request includes any below headers
            <ul>
              <li>Accept</li>
              <li>Accept-Language</li>
              <li>Content-Language</li>
              <li>Content-Type (but note the additional requirements below)</li>
              <li>DPR</li>
              <li>Downlink</li>
              <li>Save-Data</li>
              <li>Viewport-Width</li>
              <li>Width</li>
            </ul>
          </li>
          <li>
            If the Content-Type header has a value other than the following
            <ul>
              <li>application/x-www-form-urlencoded</li>
              <li>multipart/form-data</li>
              <li>text/plain</li>
            </ul>
          </li>
        </ol>
        <p>Below is the illustration of how requests are done between client and server.</p>
        <img src={prelight} alt="Preflight Http" />
        <p>Here is the full exchange headers info for a preflight request. First is the preflight request.</p>

        <Highlight language="Apache config">
          {`Request URL:https://example.com/resource
Request Method:OPTIONS
Status Code:200 
Remote Address:xxx.xxx.xxx.xxx:443

#Request Header
:authority:example.com
:method:OPTIONS
:path:/resource
:scheme:https
accept:*/*
accept-encoding:gzip, deflate, sdch, br
accept-language:en-US,en;q=0.8,zh-CN;q=0.6,zh;q=0.4
access-control-request-headers:x-requested-with
access-control-request-method:GET
cache-control:no-cache
origin:https://origin-example.com
pragma:no-cache
referer:https://origin-example.com/starting-viewpoint/
user-agent:Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/56.0.2924.87 Safari/537.36

#Response Header
access-control-allow-credentials:true
access-control-allow-headers:x-requested-with
access-control-allow-methods:GET
access-control-allow-origin:https://origin-example.com
access-control-max-age:3000
content-length:0
date:Wed, 08 Feb 2017 04:00:21 GMT
server:AmazonS3
status:200
vary:Origin, Access-Control-Request-Headers, Access-Control-Request-Method
via:1.1 11512f00e812993b9daa35c376d7cb20.cloudfront.net (CloudFront)
x-amz-cf-id:RMn2pTUv-MrxVpMfcC5XEbcdis9yP-rQ0Sa6jrwsnvn-XAW7EbXlrw==
x-cache:Miss from cloudfront`}
        </Highlight>
        <p>Second is the actual request for resource.</p>
        <Highlight language="Apache config">
          {`Request URL:https://example.com/resource
Request Method:GET
Status Code:200 
Remote Address:xxx.xxx.xxx.xxx:443

#Request Header
:authority:example.com
:method:GET
:path:/resource
:scheme:https
accept:*/*
accept-encoding:gzip, deflate, sdch, br
accept-language:en-US,en;q=0.8,zh-CN;q=0.6,zh;q=0.4
cache-control:no-cache
origin:https://origin-example.com
pragma:no-cache
referer:https://origin-example.com/starting-viewpoint/
user-agent:Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/56.0.2924.87 Safari/537.36
x-requested-with:x-json

#Response Header
accept-ranges:bytes
access-control-allow-credentials:true
access-control-allow-methods:GET
access-control-allow-origin:https://origin-example.com
access-control-max-age:3000
content-length:4199507
content-type:application/json
date:Wed, 08 Feb 2017 01:17:21 GMT
etag:"61b8a7a54f0d118f0df74403a1ef2b87"
last-modified:Tue, 07 Feb 2017 06:05:17 GMT
server:AmazonS3
status:200
vary:Origin,Access-Control-Request-Headers,Access-Control-Request-Method
via:1.1 11512f00e812993b9daa35c376d7cb20.cloudfront.net (CloudFront)
x-amz-cf-id:hUp_bOjI-Em68nRIXyBbyi5uAA5sBAwsThm8JeJpICvcMoR1Gf2vQQ==
x-cache:RefreshHit from cloudfront`}
        </Highlight>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
