import React from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import classNames from "classnames";

import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import routes from "./routes";

import bgImage from "assets/img/wcloud.svg";
import styles from "assets/jss/hwblog/views/components.js";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { path } = useRouteMatch();
  return (
    <div>
      <Header
        brand="Hans Wang Tech Blog"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={bgImage}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Hans Wang Tech Blog</h1>
                <h3 className={classes.subtitle}>
                  Hans writing about technology. Keep foolish, keep hungry. 逆水行舟，不进则退
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Switch>
          <Route exact path={path}>
            {routes.map((r, i) => (
              <r.page key={'c'+i} link={`/${r.link}`} />
            ))}
          </Route>
          {routes.map((r, i) => (
            <Route key={'a'+i} path={`/${r.link}`}>
              <r.page link={`/${r.link}`} />
            </Route>
          ))}
        </Switch>
      </div>
      <Footer />
    </div>
  );
}
