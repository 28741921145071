import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import ec2RoleScreen from "assets/img/role-on-ec2.png";
import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "IAM Role learning summary";
  const dateStamp = "20170106";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          IAM Role provides the flexibility to applications which can securely make API requests from role-profiled
          instances without manually creating and distributing AWS credentials.
        </p>
        <p>From the FAQ of IAM Role, we can see the clear difference between an IAM User and IAM Role,</p>
        <blockquote>
          <p>
            An IAM user has permanent long-term credentials and is used to directly interact with AWS services. An IAM
            role does not have any credentials and cannot make direct requests to AWS services. IAM roles are meant to
            be assumed by authorized entities, such as IAM users, applications, or an AWS service such as EC2.
          </p>
        </blockquote>
        <p>There are 2 points on the emphasis.</p>
        <ol>
          <li> IAM roles cannot make direct requests to AWS services </li>
          <li>
            {" "}
            IAM roles can be assumed by 3 different kinds,
            <ul>
              <li>IAM Users</li>
              <li>applications</li>
              <li>AWS EC2 (or ASG)</li>
            </ul>
          </li>
        </ol>
        <h4> How to understand “IAM roles cannot make direct requests to AWS services </h4>
        <p>
          IMHO, IAM users who has the credential and associated access can direct make request to AWS service using
          public APIs. (e.g. awscli or boto3). But for IAM roles, it has to fetch for the temporary credentials first
          based on the role. Then from there, the autobot or application could take use of the public api as normal
          user.
        </p>

        <h4> A typical use case for IAM role </h4>
        <ol>
          <li>Create an IAM role.</li>
          <li>Define which accounts or AWS services can assume the role.</li>
          <li>Define which API actions and resources the application can use after assuming the role.</li>
          <li>Specify the role when you launch your instances.</li>
          <li>Have the application retrieve a set of temporary credentials and use them.</li>
        </ol>
        <p>As illustrated below,</p>
        <img src={ec2RoleScreen} alt="ec2-role" width="578" height="275" />

        <h4> IAM role essential usage thinking </h4>
        <p>
          Its application focused no matter assumed by either users or EC2. Roles exists for the only purpose of serving
          a particular application’s need. For example, A role created specifically for EC2 related process:
        </p>
        <Highlight language="JSON">{`{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Effect": "Allow",
            "Action": [
                "ec2:*"
            ],
            "Resource": "*"
        },
    ]
}`}</Highlight>
        <p>
          Then this role is granted to the auto-bot agent as well as the an aws actual user so that both this user and
          bot can do the same ec2 instance related operations.
        </p>
        <Highlight language="JSON">{`{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Principal": {
        "AWS": [
          "arn:aws:iam::xxxx:user/hans.wang",
          "arn:aws:iam::xxxx:role/application-running-agent"
        ]
      },
      "Action": "sts:AssumeRole"
    }
  ]
}`}</Highlight>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
