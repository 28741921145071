import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "CSS specification weight";
  const dateStamp = "20121122";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          A question confused me long time today has been solved. Its about CSS specifications weight, especially when
          two or more CSS specifications targeting the same element, which overwriting the others problem.
        </p>
        <p>First the weight list for an CSS element selector:</p>
        <ul>
          <li>1000 points for Inline style</li>
          <li>100 points for ID selector</li>
          <li>10 points for Class selector and pseudo-classes (i.e. :hover, :focus)</li>
          <li>1 point for Element selector and pseudo-element (i.e. :before, :after)</li>
          <li>0 points for universal selectors (i.e. *, body *)</li>
        </ul>
        <p>
          Then when deciding each element’s style, all the overlapping rules for CSS will be calculated weight. Highest
          points wins!
        </p>
        <p>That’s it, simple and neat.</p>
        <p>But never forget, we still have !important to overrule all the above calculations.</p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
