import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Create ssl certificate, and install it using certutil";
  const dateStamp = "20120919";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Quote from{" "}
          <a href="http://wiki.nginx.org/NginxHttpSslModule" rel="nofollow">
            Nginx SSL document
          </a>
          <br />4 steps for creating your own ssl certificate.
        </p>
        <ol>
          <li>Create the server private key</li>
          <Highlight language="Bash">{`openssl genrsa -des3 -out hans.key 4096`}</Highlight>
          <li>
            Create the Certificate Signing Request <i>Note: this *.csr is just certificate request file</i>
          </li>
          <Highlight language="Bash">{`openssl req -new -key hans.key -out hans.csr`}</Highlight>
          <li>
            Remove the necessity of entering a passphrase for starting up nginx with SSL using the above private key
          </li>
          <Highlight language="Bash">
            {`cp hans.key hans.key.org && openssl rsa -in hans.key.org -out hans.key`}
          </Highlight>
          <li>Finally sign the certificate using the above private key and CSR</li>
          <Highlight language="Bash">
            {`openssl x509 -req -days 365 -in hans.csr -signkey hans.key -out hans.crt`}
          </Highlight>
        </ol>
        <p>Next thing is to deploy it on your nginx server using the newly signed certificate and private key.</p>
        <Highlight language="Nginx config">{`server {
    server_name YOUR_DOMAINNAME_HERE;
    listen 443;
    ssl on;
    ssl_certificate /(nginx conf dire)/hans.crt;        #Signed Certificate
    ssl_certificate_key /(nginx conf dire)/hans.key;    #Private Key
}`}</Highlight>
        <p>
          Finally import the certificate, and install it on your local machine. (Only for chrome, firefox install it on
          its own.)
          <br />
          This would need package libnss3-tools, which contains certutil to do the job of{" "}
          <b>Adding self-signed certficates</b>.<br />
          Either use firefox to export the certificate, or use the previous *.crt file, but *.crt file need to be
          renamed to *.pem.
        </p>
        <ul>
          <li>To insert a certificate record:</li>
          <Highlight language="Bash">
            {`certutil -d sql:$HOME/.pki /nssdb -A -t TC -n "(the irrelevant name to identify this cert)" -i hans.pem`}
          </Highlight>
          <li>To show all the certificate added on your local machine:</li>
          <Highlight language="Bash">{`certutil -L -d sql:$HOME/.pki /nssdb`}</Highlight>
          <li>To delete one particular certificate based on its name:</li>
          <Highlight language="Bash">{`certutil -D -n (the corresponding name for certificate to be deleted) -d sql:$HOME/.pki/nssdb`}</Highlight>
        </ul>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
