import React from "react";

import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = {
  rootTable: {
    minWidth: "30rem",
  },
};

const useStyles = makeStyles(styles);

export default function SimpleTable(props) {
  const classes = useStyles();
  const { rows, heads } = props;
  return (
    <TableContainer component={Paper}>
      <Table className={classes.rootTable}>
        {heads !== undefined && heads.length > 0 ? (
          <TableHead>
            <TableRow>
              {heads.map((h, i) => (
                <TableCell key={i}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {rows.map((r, i) => (
            <TableRow key={i}>
              {r.map((c, j) => (
                <TableCell key={j}>{c}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

SimpleTable.propTypes = {
  heads: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(PropTypes.array).isRequired,
};
