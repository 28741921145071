import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import SimpleTable from "components/Table/Table";
import Info from "components/Typography/Info.js";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Compression algorithm and practice";
  const dateStamp = "20161219";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>Last few days I tried out a bit on a compression practice. Here are some interesting thoughts.</p>
        <h5>Background</h5>
        <p>
          Large files stored with compression are usually not efficiently compressed. This leave a lorge room of
          improvement for both cost and computation.
        </p>
        <div>
          The most common compression algorithm &amp; practice now are mainly:<p></p>
          <ul>
            <li>
              gzip – most popular due to decent compress ratio, smaller memory footprint, and fastest in computational
              resource among three.
            </li>
            <li>
              bzip2 – better compression ratio than gzip, but has taken more memory on multiple compress level and
              slower in general
            </li>
            <li>
              7zip (algorithm LZMA) – not commonly distributed on unix-like os, and memory usage grows exponentially
              with compress level as well as time consumed, but given best compress ratio so far.{" "}
            </li>
          </ul>
        </div>
        <h5>Theory</h5>
        <p>
          Large established compress algo and implementation takes the compression problem in general. It treats all raw
          data indifferentlly adopting same compressing rules.
        </p>
        <p>But compression in core is about CATEGORIZING AND MINIFYING.</p>
        <p>
          So here comes our approach angle, crafting senmantics understanding about specified data sets will help
          compressing algo to tackle each data sets differently. Thus, it can achieve better compress ratio.
        </p>
        <p>
          To be more specific, data sets categorized as each columns (vertically) can form a more unified format, which
          can be more easily applied encoding algorithms on, such as Dictionary Encoding.
        </p>
        <h5>Algorithms</h5>
        <p>
          The algos will be mentioned here this time is the ones that have been used. This list may be added/updated in
          the future. The list as below,
        </p>
        <ul>
          <li>
            <b>DELTA Encoding</b> compresses data by recording the difference between values that follow each other in
            the column. This difference is recorded in a separate dictionary for each block of column values on disk.
            For example, timestamp is good case since it is always sequential. So instead of storing big chunk of
            prefix, we just store the delta. This would save us a lot of space.
          </li>
          <li>
            <b>Runlength Encoding</b> replaces a value that is repeated consecutively with a token that consists of the
            value and a count of the number of consecutive occurrences. This encoding is best suited to a table in which
            data values are often repeated consecutively, for example, when the table is sorted by those values.
          </li>
          <li>
            <b>Dictionary Encoding</b> uses a separate dictionary of unique values which is created for each block of
            column values. Instead of storing the actual value, a smaller sized dictionary token is replacing the actual
            value. This encoding is very effective when a column contains a limited number of unique values.
          </li>
          <li>
            <b>Huffman Coding</b> is a particular type of optimal prefix code that is commonly used for lossless data
            compression. In core, Huffman coding uses a specific method for choosing the representation for each symbol,
            resulting in a prefix code (sometimes called “prefix-free codes”, that is, the bit string representing some
            particular symbol is never a prefix of the bit string representing any other symbol).
          </li>
        </ul>
        <h5>Practice</h5>
        <p>According to the theory above, the implementation will mainly be composed by 3 parts.</p>
        <ul>
          <li>Firstly analyzing the data sets in semantics</li>
          <li>
            Secondly categorizing/columnizing similar data attributes using fixed pattern, which makes restructing from
            the encoded data feasible.
          </li>
          <li>
            Lastly, compress each featured data attributes using well-established compressing tools. If time allowed,
            further customized compressing programe can be made. But due to limitation of time &amp; resources, most
            compressing/decompressing customizedly made will be just as equally good as gzip on best cases given the
            experienced experiments here
          </li>
        </ul>
        <h5>Benchmark</h5>
        <p>Finally we achieved the below result by above approach.</p>
        <Info>Final Compression Ratio Benchmark</Info>
        <SimpleTable
          heads={[
            "Sample Size",
            "Raw File (B)",
            "Gz File (B)",
            "Compression Rate on GZ",
            "Our File (B)",
            "Compression Rate improved",
            "Improve Over Gzip",
          ]}
          rows={[
            ["100MB", "114462596", "11894010", "10.391%", "8613602", "7.525%", "38.08%"],
            ["500MB", "557523253", "57931368", "10.390%", "31205248", "5.597%", "85.64%"],
            ["1GB", "1098079669", "115986078", "10.562%", "62586282", "5.699%", "85.32%"],
          ]}
        />
        <Info>Final Computational Time Benchmark</Info>
        <SimpleTable
          heads={["Sample Size", "Gzip Time Spent", "Customized Time Spent"]}
          rows={[
            ["100MB", "0m2.847s", "0m48.404s"],
            ["500MB", "0m14.030s", "4m14.720s"],
            ["1GB", "0m27.649s", "10m33.379s"],
          ]}
        />
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
