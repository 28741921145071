import React from "react";
import Board from "react-trello";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import image from "assets/img/bg7.jpg";

const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(" + image + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    height: "100%",
    minHeight: "100vh",
  },
  container: {
    width: "80vw",
  },
  limitHeight: {
    maxHeight: "90vh",
  },
};
const useStyles = makeStyles(styles);

export default function TodosPage() {
  const classes = useStyles();
  const lastUpdateTime = "20200607";
  const initialData = {
    lanes: [
      {
        id: "lane1",
        title: "Certification",
        cards: [
          {
            id: "c1",
            title: "AWS Solution Architect - Associate",
            description: "https://acloud.guru/",
            label: "By end Jun",
          },
          {
            id: "c2",
            title: "AWS Solution Architect - Professional",
            description:
              "https://aws.amazon.com/whitepapers/?whitepapers-main.sort-by=item.additionalFields.sortDate&whitepapers-main.sort-order=desc",
            label: "1st in Jul",
          },
          {
            id: "c3",
            title: "GCP - Associate Cloud Engineer",
            description: "https://acloud.guru/",
            label: "Aug",
          },
          {
            id: "c4",
            title: "Scrum certified",
            description: "https://acloud.guru/",
            label: "Sep",
          },
        ],
      },
      {
        id: "lane2",
        title: "Project",
        cards: [
          {
            id: "p1",
            title: "RazilIO",
            description: "Security Platform",
            label: "Ongoing",
          },
          {
            id: "p2",
            title: "Blog Migrate",
            description: "Moving to serverless hosting",
            label: "by Jul",
          },
          {
            id: "p3",
            title: "Wetao Hosting",
            description: "Introduction about pivoting of business",
            label: "by Oct",
          },
          {
            id: "p4",
            title: "Family Time App",
            description: "React Native - get it done",
            label: "by Dec",
          },
          {
            id: "p5",
            title: "Youtube Channel",
            description: "Video uploading",
            label: "by Dec",
          },
        ],
      },
      {
        id: "lane3",
        title: "Book",
        cards: [
          {
            id: "b1",
            title: "Sprint in Action",
            label: "by Oct",
          },
          {
            id: "b2",
            title: "HackerRank ACM",
            label: "by Dec",
          },
        ],
      },
      {
        id: "lane4",
        title: "Completed",
        cards: [],
      },
    ],
  };
  const cache = localStorage.getItem("memo") !== null ? JSON.parse(localStorage.getItem("memo")) : undefined;
  let combineData;
  if (cache && moment(cache.time) > moment(lastUpdateTime)) {
    combineData = cache.data;
  } else {
    localStorage.clear();
    combineData = initialData;
  }

  const handleDataChange = (data) => {
    localStorage.setItem(
      "memo",
      JSON.stringify({
        time: moment().format("YYYYMMDD"),
        data: data,
      })
    );
  };

  return (
    <div className={classes.root}>
      <Paper elevation={2} className={classes.container}>
        <Board data={combineData} draggable editable onDataChange={handleDataChange} className={classes.limitHeight} />
      </Paper>
    </div>
  );
}
