import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Play with Laravel Elixir";
  const dateStamp = "20150831";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>An easily configured tool for frontend build</p>
        <p>
          Setting up production-ready frontend package could be time consuming and boring. If we use RequireJS with AMD
          mode, then it could easily bury you in the deep configuration of dependencies, GruntFile setting and file
          structure exclusion for source control. Luckily, we got Laravel Elixir with Gulp.
        </p>
        <p>
          So to start with, we got the code in <code>package.json</code>:
        </p>
        <Highlight language="JSON">
          {`{
  "devDependencies": {
    "gulp": "^3.8.8",
    "laravel-elixir": "*"
  }
}`}
        </Highlight>
        <p>
          This is the basic packages it required. to add more (e.g. bootstrap-sass), just list more in the dependecies
          (diff from devDependencies). Next step is,
        </p>
        <Highlight language="Bash">
          {`npm install`}
        </Highlight>
        <p>
          Then after relevant packages are installed, next one is to config <code>gulpfile.js</code> to customize
          frontend package. To start with,
        </p>
        <Highlight language="Javascript">
          {`elixir(function(mix) {
    mix.less('app.less');
    // or sass
    // mix.sass('app.scss');
});`}
        </Highlight>
        <p>
          The less or sass source code folder would be in
          <code>resources/assets/less</code>
          or
          <code>resources/assets/sass</code>
        </p>
        <p>The rest of interesting API could be,</p>
        <Highlight language="Javascript">
          {`// compile multiple less (or sass) file into one app.css
mix.less(['app.less', 'src1.less', '../vendor/lib1.less']);
 
// concat multiple js script into one js file (name specified or otherwise app.js by default)
   .scripts([
        'jquery.min.js',
        'lib_others.js',
   ], 'public/js/dst.js', sourceDir)
 
// copy dump files from one dir to dest dir
   .copy('srcDir', 'dstDir')
 
// interesting API for stamping version on files, laravel embeded elixir('file') function related
   .version(['file1.css', 'file2.js']);`}
        </Highlight>
        <p>Other 2 important console API should be remembered.</p>
        <Highlight language="Bash">
          {`gulp watch`}
        </Highlight>
        <p>This is a monitoring script to detect any source file changes, and apply gulp script on them.</p>
        <Highlight language="Bash">
          {`gulp --production`}
        </Highlight>
        <p>This is to do the minifying and uglyfying on source to make output production ready.</p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
