import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/hwblog/views/landingPageSections/configStyle.js";

const useStyles = makeStyles(styles);

export default function P07Sublime(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12}>
          <h2 className={classes.title}>
            <Link to={props.link}>My Sublime Config</Link>
          </h2>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.snippet}>
        <GridItem xs={12}>
          <p className={classes.description}>
            It doesn’t take long to prepare for a sublime config. Since I am always a big fan of sublime, been using it
            on every machine and every project I have encountered with. I realized that every time I put up an IDE
            environment, it took me some time to remember and collect handy packages I am familiar with. So I decided to
            paste my sublime config in order to future auto-provision the environment.
          </p>
          <p className={classes.description}>
            To start with, go to&nbsp;<strong>Package Control: Advanced Install Package</strong>
          </p>
          <p className={classes.description}>Then paste below comma-separated package list,</p>
          <Highlight language="Vim Script">
            {`SideBarEnhancements,SublimeLinter,SublimeLinter-eslint,SublimeLinter-phpcs,TrailingSpaces,Git,GitGutter,Terraform,Anaconda,Babel,Babel Snippets,Djaneiro,Sass,CSS3,HTML5,HTMLBeautify,HTML-CSS-JS Prettify,CSS Format,JavaScript & NodeJS Snippets,JavaScript Completions,DocBlockr,phpfmt,Laravel Blade Highlighter,Pretty JSON,Pretty YAML`}
          </Highlight>
          <p className={classes.description}>
            And most importantly in all step above, just to check the&nbsp;<strong>Package Control&nbsp;</strong>
            installation script in
          </p>
          <p className={classes.description}>
            <a href="https://packagecontrol.io/installation" target="_blank" rel="noopener noreferrer">
              https://packagecontrol.io/installation
            </a>
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
}

P07Sublime.propTypes = {
  link: PropTypes.string,
};
