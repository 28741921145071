import P01VimConfig from "./Sections/P01VimConfig.js";
import P02GitConfig from "./Sections/P02GitConfig.js";
import P03ScreenConfig from "./Sections/P03ScreenConfig.js";
import P04XmonadConfig from "./Sections/P04XmonadConfig.js";
import P05MacBashConfig from "./Sections/P05MacBashConfig.js";
import P06EslintConfig from "./Sections/P06EslintConfig.js";
import P07Sublime from "./Sections/P07Sublime.js";

const routes = [
  {
    link: "vim",
    page: P01VimConfig,
  },
  {
    link: "git",
    page: P02GitConfig,
  },
  {
    link: "screen",
    page: P03ScreenConfig,
  },
  {
    link: "xmonad",
    page: P04XmonadConfig,
  },
  {
    link: "bash",
    page: P05MacBashConfig,
  },
  {
    link: "eslint",
    page: P06EslintConfig,
  },
  {
    link: "sublime",
    page: P07Sublime,
  },
];

export default routes;
