import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import SimpleTable from "components/Table/Table";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Agile software development";
  const dateStamp = "20130328";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          <strong>Manifesto:</strong>
        </p>
        <ul>
          <li>Individuals and interactions over processes and tools</li>
          <li>Working software over comprehensive documentation</li>
          <li>Customer collaboration over contract negotiation</li>
          <li>Responding to change over following a plan</li>
        </ul>
        <p>
          <strong>According to Kent Beck, the Agile Manifesto is based on twelve principles:</strong>
        </p>
        <ul>
          <li>Customer satisfaction by rapid delivery of useful software</li>
          <li>Welcome changing requirements, even late in development</li>
          <li>Working software is delivered frequently (weeks rather than months)</li>
          <li>Working software is the principal measure of progress</li>
          <li>Sustainable development, able to maintain a constant pace</li>
          <li>Close, daily cooperation between business people and developers</li>
          <li>Face-to-face conversation is the best form of communication (co-location)</li>
          <li>Projects are built around motivated individuals, who should be trusted</li>
          <li>Continuous attention to technical excellence and good design</li>
          <li>Simplicity—the art of maximizing the amount of work not done—is essential</li>
          <li>Self-organizing teams</li>
          <li>Regular adaptation to changing circumstances</li>
        </ul>
        <p>译文如下(Translation)：</p>
        <SimpleTable
          heads={["Important", "Less Important"]}
          rows={[
            ["个体和互动", "流程和工具"],
            ["工作的软件", "详尽的文档"],
            ["客户合作", "合同谈判"],
            ["响应变化", "遵循计划"],
          ]}
        />
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
