import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/hwblog/views/landingPageSections/configStyle.js";

const useStyles = makeStyles(styles);

export default function P01VimConfig(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12}>
          <h2 className={classes.title}>
            <Link to={props.link}>My Vim Config</Link>
          </h2>
          <h5 className={classes.description}>My Vim config. Written in ~/.vimrc</h5>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.snippet}>
        <GridItem xs={12}>
          <Highlight language="Vim Script">
            {`set background=dark
colorscheme solarized
syntax on 
set nocompatible 
set backspace=indent,eol,start 
set expandtab shiftwidth=4 tabstop=4 softtabstop=4
set cindent 
set smartindent 
set selectmode=mouse 
set nobackup 
set nowritebackup 
set noswapfile 
set history=200 
set ruler 
set number 
set showcmd 
set incsearch 
set hlsearch 
set ignorecase 
set showmatch 
set laststatus=2
set tabpagemax=30

map <Right> <Esc>:tabn<CR>
map <Left> <Esc>:tabp<CR>`}
          </Highlight>
        </GridItem>
      </GridContainer>
    </div>
  );
}

P01VimConfig.propTypes = {
  link: PropTypes.string,
};