import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/hwblog/views/landingPageSections/configStyle.js";

const useStyles = makeStyles(styles);

export default function P03ScreenConfig(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12}>
          <h2 className={classes.title}>
            <Link to={props.link}>GNU Screen Config</Link>
          </h2>
          <h5 className={classes.description}>My GNU Screen config Written in ~/.screenrc</h5>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.snippet}>
        <GridItem xs={12}>
          <Highlight language="Bash">
            {`# Look and feel
caption always "%{= bb}%{+b w}Screen: %n | %h %=%t %c"
hardstatus alwayslastline "%-Lw%{= BW}%50>%n%f* %t%{-}%+Lw%< "
 
# Our warning of activity
activity "Activity in %t(%n)"`}
          </Highlight>
          <h5 className={classes.description}>Along with the Frequent GNU Screen cheat sheet:</h5>
          <Highlight language="Bash">
            {`Ctrl-a + c           -> cre­ate new win­dow
Ctrl-a + A           -> set win­dow name
Ctrl-a + w           -> show all win­dow
Ctrl-a + 1|2|3|…     -> switch to win­dow n
Ctrl-a + d           -> detach win­dow
Ctrl-a + k           -> kill window
Ctrl-a + [           -> start copy, move cur­sor to the copy loca­tion, press ENTER, select the chars, press ENTER to copy the selected char­ac­ters to the buffer
Ctrl-a + ]           -> paste from buffer
Ctrl-a + S           -> split display horizontally
Ctrl-a + V           -> split display vertically`}
          </Highlight>
          <h5 className={classes.description}>And screen init session:</h5>
          <Highlight language="Bash">
            {`screen -S <em>name</em>      #Start a new screen session with name
screen -ls                   #list running sessions/screens
screen -r                    #attach to a running session
screen -r <em>name</em>      #attach to session with name`}
          </Highlight>
        </GridItem>
      </GridContainer>
    </div>
  );
}

P03ScreenConfig.propTypes = {
  link: PropTypes.string,
};
