import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Handy script to work with PDF files";
  const dateStamp = "20160620";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          PDF is commonly used printing file format. Creating &amp; organising it might be a bit hard if without Acrobat
          Pro’s help.
        </p>
        <p>
          Today I discovered some open source lib to manipulate PDF files, including{" "}
          <strong>Creating, Splitting, Merging, and Re-Ordering</strong> PDF pages.
        </p>
        <p>
          Below is the script line for{" "}
          <em>
            <strong>creating</strong>
          </em>{" "}
          PDF,
        </p>
        <Highlight language="Bash">{`echo 'Hello World!' | enscript -B -o - | ps2pdf - content.pdf`}</Highlight>
        <p>
          <em>-B for enscript omit the header for the output</em>
        </p>
        <p>Here is the script for re-ordering the raw PDF into a book-style printable version.</p>
        <Highlight language="Bash">{`#!/bin/bash
 
 if [ $# -ne 2 ] ; then
     echo "Invalid input supplied, please choose input PDF and page counts"
     echo "    example : ./format.sh <input.pdf> <pageCount>"
     exit 1
 fi
  
 echo "Formatting begins ... "
 file="$1"
 pageCount="$2"
  
 let "blankPageCount = $pageCount % 4"
  
 # Check if blank page padding needed, if so create one
 if [ $blankPageCount -ne 0 ] ; then
     echo ' ' | ps2pdf - blank.pdf 
 fi
  
 # setup each fold loop - one fold means one print page
 let "foldCount = $pageCount / 4"
 startFold=0
 pageOrganizor=''
  
 echo "    total $foldCount printing slides generated";
 echo "    with $blankPageCount blank pages";
  
 while [ $startFold -lt $foldCount ] ; do
     let "coverPrintpage = $startFold * 4 + 4"
     let "leftPrintpage = $startFold * 4 + 1"
     let "backPrintpage = $startFold * 4 + 3"
     pageOrganizor="$pageOrganizor A$coverPrintpage A$leftPrintpage-$backPrintpage"
     let "startFold += 1"
 done
  
 if [ $blankPageCount -eq 0 ] ; then
     cmd="pdftk A=$file cat$pageOrganizor output fmt_output.pdf"
 elif [ $blankPageCount -eq 3 ] ; then
     let "blankStartPage = $pageCount-2"
     cmd="pdftk A=$file B=blank.pdf cat$pageOrganizor B A$blankStartPage-$pageCount output fmt_output.pdf"
 elif [ $blankPageCount -eq 2 ] ; then
     let "blankStartPage = $pageCount-1"
     cmd="pdftk A=$file B=blank.pdf cat$pageOrganizor B A$blankStartPage A$pageCount B output fmt_output.pdf"
 else
     cmd="pdftk A=$file B=blank.pdf cat$pageOrganizor B A$pageCount B B output fmt_output.pdf"
 fi
  
 echo "Merging pdfs ... "
 $cmd
  
 # cleanning up
 echo "Cleanup and done"
 if [ -e "blank.pdf" ] ; then
     rm blank.pdf
 fi`}</Highlight>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
