import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "PostCSS learning summary";
  const dateStamp = "20161213";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          {" "}
          PostCSS is a tool for transforming styles with JS plugins. These plugins can lint your CSS, support variables
          and mixins, transpile future CSS syntax, inline images, and more
        </p>
        <p>
          This post is a bit long overdue. I try to keep up with what I have explored with PostCSS, which so far is
          absolutely elegant, fun, well-structured solution to frontend CSS.
        </p>
        <p>
          In technical, it supports gulp, webpack and npm/cli run. Since webpack is most popular among the community at
          the moment, so sample as below,
        </p>
        <Highlight language="Javascript">{`module.exports = {
    module: {
        loaders: [
            {
                test:   /\\.css$/,
                loader: "style-loader!css-loader!postcss-loader"
            }
        ]
    },
    postcss: function () {
        return [require('autoprefixer'), require('cssnext')];
    }
}`}</Highlight>
        <p>After setting up, there are several few key points of PostCSS features worth covering, it includes,</p>
        <ol>
          <li>Autoprefixer</li>
          <li>CSSnext</li>
          <li>CSS Modules</li>
          <li>Stylelint</li>
          <li>PreCSS</li>
        </ol>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
