import A01s3album from "./Sections/A01s3album.js";
import A02psaux from "./Sections/A02psaux.js";
import A03linuxmem from "./Sections/A03linuxmem.js";
import A04seo from "./Sections/A04seo.js";
import A05nginx from "./Sections/A05nginx.js";
import A06sedawk from "./Sections/A06sedawk.js";
import A07createssl from "./Sections/A07createssl.js";
import A08cssweight from "./Sections/A08cssweight.js";
import A09dao from "./Sections/A09dao.js";
import A10aud from "./Sections/A10aud.js";
import A11agile from "./Sections/A11agile.js";
import A12decorator from "./Sections/A12decorator.js";
import A13flightradar from "./Sections/A13flightradar.js";
import A14dbnormal from "./Sections/A14dbnormal.js";
import A15strend from "./Sections/A15strend.js";
import A16bladetemplate from "./Sections/A16bladetemplate.js";
import A17phpversion from "./Sections/A17phpversion.js";
import A18sublime from "./Sections/A18sublime.js";
import A19laravel from "./Sections/A19laravel.js";
import A20tpg from "./Sections/A20tpg.js";
import A21phpsoap from "./Sections/A21phpsoap.js";
import A22up56 from "./Sections/A22up56.js";
import A23longday from "./Sections/A23longday.js";
import A24node from "./Sections/A24node.js";
import A25quora from "./Sections/A25quora.js";
import A26elixir from "./Sections/A26elixir.js";
import A27gitcommit from "./Sections/A27gitcommit.js";
import A28xdebug from "./Sections/A28xdebug.js";
import A29php7 from "./Sections/A29php7.js";
import A30online from "./Sections/A30online.js";
import A31pdf from "./Sections/A31pdf.js";
import A32stayhf from "./Sections/A32stayhf.js";
import A33docker from "./Sections/A33docker.js";
import A34prodlaunch from "./Sections/A34prodlaunch.js";
import A35postcss from "./Sections/A35postcss.js";
import A36compression from "./Sections/A36compression.js";
import A37jupyter from "./Sections/A37jupyter.js";
import A38iam from "./Sections/A38iam.js";
import A39cors from "./Sections/A39cors.js";
import A40lemp from "./Sections/A40lemp.js";
import A41talend from "./Sections/A41talend.js";
import A42terraform from "./Sections/A42terraform.js";
import A43splunk from "./Sections/A43splunk.js";
import A44smacss from "./Sections/A44smacss.js";
import A45cubicbezier from "./Sections/A45cubicbezier.js";
import A46migration from "./Sections/A46migration.js";
import A47keyexchange from "./Sections/A47keyexchange.js";
import A48xsstypes from "./Sections/A48xsstypes.js";
import A49tlsdiff from "./Sections/A49tlsdiff.js";
import A50pfs from "./Sections/A50pfs.js";
import A51securitybp from "./Sections/A51securitybp.js";
import A52http2 from "./Sections/A52http2.js";

const routes = [
  {
    link: "tls-12-vs-tls-13",
    page:  A49tlsdiff,
  },
  {
    link: "types-of-xss",
    page:  A48xsstypes,
  },
  {
    link: "diffie-hellman-key-exchange",
    page:  A47keyexchange,
  },
  {
    link: "blog-migration",
    page:  A46migration,
  },
  {
    link: "cubit-bezier-function-in-css-animation",
    page:  A45cubicbezier,
  },
  {
    link: "smacss-architecture-writing-css",
    page:  A44smacss,
  },
  {
    link: "study-splunk",
    page:  A43splunk,
  },
  {
    link: "note-on-terraform-n-terragrunt",
    page:  A42terraform,
  },
  {
    link: "dive-in-talend-studio",
    page:  A41talend,
  },
  {
    link: "docker-lemp-stack",
    page:  A40lemp,
  },
  {
    link: "cors-http-requests",
    page:  A39cors,
  },
  {
    link: "iam-role-learning-summary",
    page:  A38iam,
  },
  {
    link: "jupyter-with-spark-setup",
    page:  A37jupyter,
  },
  {
    link: "compression-algorithm-and-practice",
    page:  A36compression,
  },
  {
    link: "postcss-learning-summary",
    page:  A35postcss,
  },
  {
    link: "press-relation-for-new-product-launch",
    page:  A34prodlaunch,
  },
  {
    link: "difference-docker-and-vagrant",
    page:  A33docker,
  },
  {
    link: "stay-hungry-stay-foolish",
    page:  A32stayhf,
  },
  {
    link: "script-to-pdf",
    page:  A31pdf,
  },
  {
    link: "outline-restful-api-system",
    page:  A30online,
  },
  {
    link: "php-7-preview",
    page:  A29php7,
  },
  {
    link: "enable-xdebug-on-php-development",
    page:  A28xdebug,
  },
  {
    link: "seven-rules-of-great-git-commit-message",
    page:  A27gitcommit,
  },
  {
    link: "play-with-laravel-elixir",
    page:  A26elixir,
  },
  {
    link: "quora-read-about-job-difficulty",
    page:  A25quora,
  },
  {
    link: "play-with-node-express-jade-mongoose",
    page:  A24node,
  },
  {
    link: "days-are-long-but-decades-are-short",
    page:  A23longday,
  },
  {
    link: "shock-life-is-fase-56-up",
    page:  A22up56,
  },
  {
    link: "tpg-acquired-iinet",
    page:  A21phpsoap,
  },
  {
    link: "tpg-acquired-iinet",
    page:  A20tpg,
  },
  {
    link: "laravel-socialite-composer-autoload",
    page:  A19laravel,
  },
  {
    link: "sublime-3-plugins",
    page:  A18sublime,
  },
  {
    link: "php-version-difference",
    page:  A17phpversion,
  },
  {
    link: "blade-template-tags-laravel",
    page:  A16bladetemplate,
  },
  {
    link: "google-search-trend",
    page:  A15strend,
  },
  {
    link: "database-normalization",
    page:  A14dbnormal,
  },
  {
    link: "flight-radar",
    page:  A13flightradar,
  },
  {
    link: "decorator-inheritance",
    page: A12decorator,
  },
  {
    link: "agile-software-development",
    page: A11agile,
  },
  {
    link: "aud-exchange-rate-trend-analysis",
    page: A10aud,
  },
  {
    link: "data-access-object-pattern",
    page: A09dao,
  },
  {
    link: "css-specification-weight",
    page: A08cssweight,
  },
  {
    link: "create-ssl-certificate-using-certutil",
    page: A07createssl,
  },
  {
    link: "sed-n-awk",
    page: A06sedawk,
  },
  {
    link: "nginx-basic-auth",
    page: A05nginx,
  },
  {
    link: "seo-browser-tool",
    page: A04seo,
  },
  {
    link: "linux-memory-management",
    page: A03linuxmem,
  },
  {
    link: "study-ps-aux",
    page: A02psaux,
  },
  {
    link: "static-photo-album-hosted-on-s3",
    page: A01s3album,
  }
];

export default routes;
