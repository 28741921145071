import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Shock Shock Shock, life is fast – 56 UP";
  const dateStamp = "20150318";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <h5>
          <i>56 UP Review</i>
        </h5>
        <p>
          I got tremendous shock after view the moving called 56 up. Its way of expressing one’s life in a flash urges
          me doing something worthy immediately.
        </p>
        <p>
          Once I were young, I thought there will always be plenty of time to plan things, and lay back a bit to enjoy
          the life a little bit, and push other important things later. Because I knew at that time, for certain, if I
          became determined at some later moment, I will finish the task which was important to me.
        </p>
        <h5>However, that moment never came…</h5>
        <p>
          I always find myself lots of reasons to make myself believe that I was delayed for so many practical reasons,
          holding the fear of facing the fact that I was behind schedule.
        </p>
        <p>
          Life is to short for planning and holding unrealistic reasons to wait, to hesitate. In a flash, I am already
          near 30. I dare to say, when I am near 50 and started looking back, life is still in a flash. And you will
          never know where all the time has been gone when you can achieve so many and so much while you were 30.
        </p>
        <p>
          Life has been given equally to all human being in terms of time. The question is whether one can seize it and
          accomplish something worthwhile.
        </p>
        <p>
          The deepest fear in me now is spending the time for absolutely nothing, accomplished nothing. Waste my life in
          wandering. When look back, there is nothing worth mentioning. And life is already behind me, and then started
          deeply question myself, what have I done to my life?
        </p>
        <p>
          Most people won’t regret, at lease won’t admit it to themselves. But the truth is they know for sure there is
          nothing can be done to fix the issue which are already happened. There are so many thing cannot be undone once
          the timing is passed.
        </p>
        <p>So seize the opportunity now, and leave no regret in the future.</p>
        <p>Push harder, and harder. The only reason of being unsatisfied is not pushing hard enough.</p>
        <p>
          Only in that sense, one can say for sure, my life has reached the upmost limitation. Even that limitation is
          so insignificant comparing to others. You know for sure, you cannot do any better even if with a second chance
          granted.
        </p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
