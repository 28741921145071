import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import SimpleTable from "components/Table/Table";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Decorator v.s. Inheritance";
  const dateStamp = "20131205";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <h5>Performance-wise compare decorator &amp; inheritance</h5>
        <SimpleTable
          heads={["Decorator", "Inheritance"]}
          rows={[
            ["Object isolated logic", "Class dependent"],
            ["Dynamically configured in Runtime", "Static define function & attributes"],
            [
              "Add multiple stack of decorators to combine functionality at runtime",
              "Different object initialization with multiple inherited class which is pre-defined",
            ],
            [
              "Involving creating many small objects, and complicating the process of initializing & further debug.",
              "Comparatively simple to initial object and isolate bug related to certain class",
            ],
          ]}
        />
        <h5>
          Great example code cited from{" "}
          <a
            href="http://stackoverflow.com/questions/12379848/decorator-design-pattern-vs-inheritance"
            target="_blank"
            rel="noopener noreferrer"
          >
            StackOverflow
          </a>
        </h5>
        <p>Decorator style:</p>
        <Highlight language="C++">
          {`Stream sPlain = Stream();
Stream sEncrypted = EncryptedStream(Stream());
Stream sZipped = ZippedStream(Stream());
Stream sZippedEncrypted = ZippedStream(EncryptedStream(Stream());
Stream sEncryptedZipped = EncryptedStream(ZippedStream(Stream());`}
        </Highlight>
        <p>and Inheritance style:</p>
        <Highlight language="C++">
          {`class Stream() {...}
class EncryptedStream() : Stream {...}
class ZippedStream() : Stream {...}
class ZippedEncryptedStream() : EncryptedStream {...}
class EncryptedZippedStream() : ZippedStream {...}`}
        </Highlight>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
