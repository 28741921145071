import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function A06sedawk(props) {
  const classes = useStyles();

  const title = "sed & awk";
  const dateStamp = "20120919";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>sed is file line based processing. Each line is executed individually.</p>
        <Highlight language="Bash">
          {`sed 's/(match_pattern)/(replace_pattern)'`}
        </Highlight>
        <p>awk is useful when trying to formatting the string.</p>

        <Highlight language="Bash">
          {`awk -F(field separator) '(execute command)' file`}
        </Highlight>
        <p>
          <i>(field separator)</i> is defaultly set to be <i>tab</i>.<br />
          <i>(execute command)</i> will become very handy with <i>print</i> and $1, $2, etc.
        </p>
      </div>
    </div>
  );
}

A06sedawk.propTypes = {
  link: PropTypes.string,
};
