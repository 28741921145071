import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/hwblog/views/landingPageSections/configStyle.js";

const useStyles = makeStyles(styles);

export default function P06EslintConfig(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12}>
          <h2 className={classes.title}>
            <Link to={props.link}>Eslint Config</Link>
          </h2>
          <h5 className={classes.description}>Prettier is too strong opinionated, eslint could do most of the formatting job. Here is a handy list</h5>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.snippet}>
        <GridItem xs={12}>
          <h5 className={classes.description}>
            Package Selection - package.json
          </h5>
          <Highlight language="Json">
            {`"@babel/cli": "7.5.5",
"@babel/core": "7.5.5",
"@babel/plugin-proposal-class-properties": "^7.10.1",
"@babel/preset-env": "7.5.5",
"@babel/preset-react": "7.0.0",
"eslint": "^6.6.0",
"eslint-plugin-import": "^2.20.2",
"eslint-plugin-react": "^7.20.0"
`}
          </Highlight>
          <h5 className={classes.description}>
            Babel config - .babelrc
          </h5>
          <Highlight language="Json">{`{
  "presets": [
    "@babel/env",
    "@babel/react"
  ],
  "plugins": [
    "@babel/plugin-proposal-class-properties"
  ]
}
`}</Highlight>
          <h5 className={classes.description}>
            Eslint config - .eslintrc.json
          </h5>
          <Highlight language="Json">
            {`{
  "parser": "babel-eslint",
  "env": {
    "es6": true,
    "browser": true
  },
  "parserOptions": {
    "ecmaVersion": 6,
    "sourceType": "module",
    "ecmaFeatures": {
      "jsx": true
    }
  },
  "settings": {
    "react": {
      "pragma": "React",
      "version": "detect"
    },
    "import/resolver": {
      "node": {
        "paths": ["src"]
      }
    }
  },
  "plugins": ["react", "import"],
  "extends": ["eslint:recommended", "plugin:react/recommended", "plugin:import/errors", "plugin:import/warnings"],
  "rules": {
    "import/order": [
      "error",
      {
        "groups": ["index", "sibling", "external", "parent", "internal", "builtin"],
        "pathGroups": [
          {
            "pattern": "react*",
            "group": "index",
            "position": "before"
          },
          {
            "pattern": "prop-types",
            "group": "index",
            "position": "after"
          },
          {
            "pattern": "@material-ui/**",
            "group": "sibling",
            "position": "before"
          },
          {
            "pattern": "./**",
            "group": "internal",
            "position": "after"
          },
          {
            "pattern": "assets/**",
            "group": "builtin",
            "position": "before"
          }
        ],
        "pathGroupsExcludedImportTypes": ["react"],
        "newlines-between": "always",
        "alphabetize": {
          "order": "asc",
          "caseInsensitive": true
        }
      }
    ]
  }
}
`}
          </Highlight>
        </GridItem>
      </GridContainer>
    </div>
  );
}

P06EslintConfig.propTypes = {
  link: PropTypes.string,
};
