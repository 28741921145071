import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import frScreen from "assets/img/flight-radar.png";
import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "FlightRadar nice cool API map";
  const dateStamp = "20140313";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          In memory of the recent accident of Malaysia Airlines flight MH370, more details{" "}
          <a
            href="http://www.flightradar24.com/simple_index.php?lat=-33.8&amp;lon=151&amp;z=10"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </p>
        <img src={frScreen} alt="flight radar screenshot" />
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
