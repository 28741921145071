import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/hwblog/views/landingPageSections/configStyle.js";

const useStyles = makeStyles(styles);

export default function P02GitConfig(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12}>
          <h2 className={classes.title}>
            <Link to={props.link}>My Git Config</Link>
          </h2>
          <h5 className={classes.description}>My Git config Written in ~/.gitconfig</h5>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.snippet}>
        <GridItem xs={12}>
          <Highlight language="INI">
            {`[user]
    name = Hans.W
    email = hanswang2012@gmail.com
[alias]
    st = status
    ci = commit
    co = checkout
    br = branch
    df = diff
    rt = remote -v
    last = log -2 HEAD
    tree = log --oneline --decorate --all --graph
[color]
    branch = auto
    diff = auto
    interactive = auto
    status = auto
[credential]
    helper = cache --timeout=3600
[push]
    default = simple
[pager]
    branch = false
`}
          </Highlight>
        </GridItem>
      </GridContainer>
    </div>
  );
}

P02GitConfig.propTypes = {
  link: PropTypes.string,
};