import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Database Normalization";
  const dateStamp = "20140402";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Database normalization is the common term to describe an good schema of db tables. Design tables with keeping
          normalization in mind.
        </p>
        <p>1NF: each row has a unique identifier, and don’t contain any repeating group of data in same column.</p>
        <p>2NF: plus, none of partial independency of any column on primary key.</p>
        <p>3NF: plus, every non-prime attributes must be dependent on primary key.</p>
        <p>Normalization aims to reduce data redundancy, usually with break-down tables.</p>
        <p>There are also BCNF, 4NF, 5NF. (More to come).</p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
