import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Docker LEMP stack build";
  const dateStamp = "20170214";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>Docker is great. It provides lightweight virtualization with almost zero overhead.</p>
        <p>
          And out here are tons of articles about docker{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://docs.docker.com/engine/userguide/eng-image/dockerfile_best-practices/"
          >
            best practice
          </a>{" "}
          It has short-versioned bullet points as below,
        </p>
        <ol>
          <li>Containers should be ephemeral</li>
          <li>Use a .dockerignore file</li>
          <li>Avoid installing unnecessary packages</li>
          <li>Each container should have only one concern as “one process per container” may not be all-time true.</li>
          <li>Minimize the number of layers</li>
          <li>Sort multi-line arguments</li>
          <li>Build cache</li>
        </ol>
        <p>
          Given docker is so good to have, I took an initiative with the intention of putting general PHP development
          env into docker. So I built a small git repo about docker lemp stack.
        </p>
        <p>
          <a href="https://github.com/hanswang/docker-lemp" target="_blank" rel="noopener noreferrer">
            Github Hanswang/docker-lemp
          </a>
        </p>
        <p>From this repo, we take use of docker-compose.</p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
