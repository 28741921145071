import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function A04seo(props) {
  const classes = useStyles();

  const title = "SEO Browser, nice tool for in page SEO";
  const dateStamp = "20120916";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>It simplify of finding the internal / external links and h1, h2 tag, etc.</p>
        <p>It strips out all the CSS style, and JS effect.</p>
        <p>Nice to detect what “SEO best practice” modifications need to be done.</p>
        <p>
          Link as:&nbsp;
          <a href="http://www.browseo.net/" rel="nofollow">
            http://www.browseo.net/
          </a>
        </p>
      </div>
    </div>
  );
}

A04seo.propTypes = {
  link: PropTypes.string,
};
