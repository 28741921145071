import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";
import Quote from "components/Typography/Quote.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Data Access Object Pattern";
  const dateStamp = "20121127";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>DAO is access object, and is definitely categorized as C in MVC scheme.</p>

        <Quote
          text={
            "An object that provides an abstract interface to some type of database or other persistence mechanism. By mapping application calls to the persistence layer, DAOs provide some specific data operations without exposing details of the database."
          }
          author={"wiki"}
        />
        <p></p>
        <p>
          DAO is solely responsible to DAO clients. And DAO clients should not have knowledge of the database schema as
          well as each others’ functionality.
        </p>
        <p>
          DAO provide the transparent layer for its clients to DB handling. In this sense, it is also required that an
          fully functioned DAO should cover all the aspects of an DB’s ever contained information which are intended for
          public access of course.
        </p>
        <p>
          <b>The question of why we need DAO instead of handler/object ?</b>
        </p>
        <p>
          The answer should be straightforward obvious from the above argument. However, to sum it up, it is quite
          critical that we have an handler which focus on{" "}
          <strong>nothing but covering all the information from DB</strong>.
        </p>
        <p>
          To the contrary, Handlers/Objects are for building up apps only. They are designed and customized based on the
          apps’ needs, and is oriented by the apps’ functionality. They do not necessarily fulfill all the needs from
          DB.
        </p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
