import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Great reading about relation with press for new product launch";
  const dateStamp = "20160923";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          In order to reach out for the product to targeted customers, the press plays a vital role. How to reach out? I
          found an interesting reading today at{" "}
          <a
            href="http://www.themacro.com/articles/2016/09/getting-press-for-your-startup/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </p>
        <p>This article enlightened me about generating news instead of bragging about the feature of your product.</p>
        <blockquote>
          <p>
            News is timely. It makes more sense to talk about it today rather than tomorrow. News attracts attention now
            and that’s what reporters want
          </p>
        </blockquote>
        <p>And what’s more, in the comments, further explanation says,</p>
        <blockquote>
          <p>
            Journalists don’t want to advertise your startup for free, they care about writing a story that entertains
            and educates their readers. Feed one to them.
          </p>
        </blockquote>
        <p>Back to the article, it highlighted 4 bullet points to tackle this problem in right direction.</p>
        <ol>
          <li>
            Generate News
            <ul>
              <li>Product Launches and Features</li>
              <li>Product/Sales Milestones</li>
              <li>Significant BD Deals or Customers</li>
              <li>Fundraising</li>
            </ul>
          </li>
          <li>Make Your Own Press Contacts</li>
          <li>Pitch Those Contacts an Exclusive</li>
          <li>Share News at a Consistent Cadence</li>
          <li>
            Just like BD, don’t expect to get every meeting and story. When a reporter says “no”, ask yourself why.
          </li>
        </ol>
        <p>Also here is a very interesting tools for EDM,</p>
        <p>
          <a href="http://www.artofemails.com/pitch-press" target="_blank" rel="noopener noreferrer">
            Pitch templates using different angles
          </a>
        </p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
