import { container, title, primaryColor } from "assets/jss/hwblog.js";
import imagesStyle from "assets/jss/hwblog/imagesStyles.js";

const profilePageStyle = {
  container,
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "320px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "45rem",
    color: "#999",
    textAlign: "center !important"
  },
  nested: {
    paddingLeft: "2rem"
  },
  name: {
    marginTop: "-80px"
  },
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  helperText: {
    marginTop: "1rem",
  },
  highlightText: {
    backgroundColor: "yellow",
    color: "black",
    "&:hover": {
      color: "yellow",
      fontSize: "110%",
      backgroundColor: "black"
    }
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center"
  },
  hoverHighlight: {
    color: "#999",
    "&:hover": {
      color: primaryColor
    }
  },
  pageDivider: {
    marginTop: "2rem",
    height: 0
  },
  focused: {
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.54)",
    paddingBottom: "1rem"
  },
  carouselBox: {
    width: "45rem",
    margin: "0 auto"
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center"
  }
};

export default profilePageStyle;
