import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "TPG acquired iiNet for 1.4 Billion AU dollars";
  const dateStamp = "20150313";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Absolutely out of no where, I heard colleagues talking about iiNet acquisition this morning after I stepped
          into the office.
        </p>
        <p>Firstly, one colleague came to us say “HiFI”, and others are applauding.</p>
        <p>
          Then several minutes later, majority of people are discussing about the stock price. Saying they should buying
          more beforehand.
        </p>
        <p>It turns out the stock price really rock up from 7.4 to 9.1 (17:03 at Syndey time 13rd March, 2015).</p>
        <p>What a shocking news. This is a Capital Game!</p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
