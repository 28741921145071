import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import SimpleTable from "components/Table/Table";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Almighty Sublime 3 plugins";
  const dateStamp = "20150226";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>To begin with, here is the code snippet to install sublime Package Control. (sublime text 3)</p>
        <Highlight key={0} language="Bash">
          {`import urllib.request,os,hashlib; h = 'eb2297e1a458f27d836c04bb0cbaf282' + 'd0e7a3098092775ccb37ca9d6b2e4b7d'; pf = 'Package Control.sublime-package'; ipp = sublime.installed_packages_path(); urllib.request.install_opener( urllib.request.build_opener( urllib.request.ProxyHandler()) ); by = urllib.request.urlopen( 'http://packagecontrol.io/' + pf.replace(' ', '%20')).read(); dh = hashlib.sha256(by).hexdigest(); print('Error validating download (got %s instead of %s), please try manual install' % (dh, h)) if dh != h else open(os.path.join( ipp, pf), 'wb' ).write(by) `}
        </Highlight>
        <p>After the installation, the fun part begins.</p>
        <ol>
          <li>
            <strong>Javascript Beautify</strong> – This makes the javascript code tidy and neat, to suit the code
            standard. Using <code>ctrl+alt+f</code>
          </li>
          <li>
            <strong>Javascript Patterns</strong> – This incredibly increase your speed of coding in javascript. More
            juicy details in below
          </li>
          <li>
            <strong>HTMLBeautify</strong> – This prettify the HTML code using <code>ctrl+alt+shift+f</code>
          </li>
          <li>
            <strong>PHPDoc</strong> – This provides convenient way of writing php code coments
          </li>
        </ol>
        <p>
          About <b>Javascript Patterns</b>
        </p>
        <SimpleTable
          heads={["Trigger", "Snippet"]}
          rows={[
            [
              <code key={0}>ifun</code>,
              <Highlight language="javascript" key={1}>
                {`;(function() {
    'use strict';
    // closure scope
}());`}
              </Highlight>,
            ],
            [
              <code key={0}>forin</code>,
              <Highlight language="javascript" key={1}>
                {`for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
        obj[prop];
    }
}`}
              </Highlight>,
            ],
            [
              <code key={0}>ifor</code>,
              <Highlight language="javascript" key={1}>
                {`for (i = 0, len = arr.length; i & lt; len; i++) {
    // array length is calculated only 1 time and then stored
}`}
              </Highlight>,
            ],
            [
              <code key={0}>constructor</code>,
              <Highlight language="javascript" key={1}>
                {`var ConstructorName = (function() {
    'use strict';
 
    function ConstructorName(arg1, arg2) {
        // enforces new
        if (!(this instanceof ConstructorName)) {
            return new ConstructorName();
        }
        // constructor body
    }
 
    ConstructorName.prototype.someMethod = function(arg) {
        // method body
    }
 
    return ConstructorName;
 
}());`}
              </Highlight>,
            ],
            [
              <code key={0}>singleton</code>,
              <Highlight language="javascript" key={1}>
                {`var singletonName = (function() {
    'use strict';
    var instance;
 
    singletonName = function() {
        if (instance) {
            return instance;
        }
 
        instance = this;
 
        // your code goes here
    };
 
    return singletonName;
 
}());`}
              </Highlight>,
            ],
            [
              <code key={0}>module</code>,
              <Highlight language="javascript" key={1}>
                {`var moduleName = (function() {
    'use strict';
    var privateVar = '';
 
    var moduleName = {
        init: {
            // kickoff
        }
    }
 
    return moduleName;
 
}());`}
              </Highlight>,
            ],
            [
              <code key={0}>rmodule</code>,
              <Highlight language="javascript" key={1}>
                {`var revealingModule = (function() {
    'use strict';
    var privateVar = 'foo';
    var publicVar = 'bar';
 
    function privateFunction() {}
 
    function publicFunction() {}
 
    return {
        publicVar: publicVar,
        publicFunction: publicFunction
    };
}());`}
              </Highlight>,
            ],
            [
              <code key={0}>Memoization</code>,
              <Highlight language="javascript" key={1}>
                {`var expensiveFunction = (function() {
    'use strict';
    var funcMemoized = function() {
        var cacheKey = JSON.stringify(Array.prototype.slice.call(arguments));
        var result;
 
        if (!funcMemoized.cache[cacheKey]) {
            // your expensive computation goes here
            funcMemoized.cache[cacheKey] = result;
        }
 
        return funcMemoized.cache[cacheKey];
    }
 
    funcMemoized.cache = {};
 
    return funcMemoized;
}());`}
              </Highlight>,
            ],
            [
              <code key={0}>Throttle</code>,
              <Highlight language="javascript" key={1}>
                {`var onResize = (function() {
    'use strict';
    var timeWindow = 200; // time in ms
    var lastExecution = new Date((new Date()).getTime() - timeWindow);
 
    var onResize = function(args) {
        // your code goes here
    };
 
    return function() {
        if ((lastExecution.getTime() + timeWindow) & lt; = (new Date()).getTime()) {
            lastExecution = new Date();
            return onResize.apply(this, arguments);
        }
    };
}());`}
              </Highlight>,
            ],
            [
              <code key={0}>Debounce</code>,
              <Highlight language="javascript" key={1}>
                {`var autocomplete = (function() {
    'use strict';
    var timeWindow = 500; // time in ms
    var timeout;
 
    var autocomplete = function(arg1, arg2) {
        // your code goes here
    };
 
    return function() {
        var context = this;
        var args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
            autocomplete.apply(context, args);
        }, timeWindow);
    };
}());`}
              </Highlight>,
            ],
            [
              <code key={0}>namespace</code>,
              <Highlight language="javascript" key={1}>
                {`(function(namespace) {
    'use strict';
    // your code goes here
    namespace.method = function() {};
})(window.namespace = window.namespace || {});`}
              </Highlight>,
            ],
          ]}
        />
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
