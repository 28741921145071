import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import tlsDiff from "assets/img/tlsdiff.png";
import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "TLS concept and Key differences between TLS 1.2 vs TLS 1.3";
  const dateStamp = "2021-11-22";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          As a cryptographic protocol, Transport Layer Security encrypts data and authenticates connections when moving
          data over the internet via HTTP—an extension of the protocol known as HTTPS (Hyper Text Transfer Protocol
          Secure).
        </p>
        <h5>History</h5>
        <p>
          In the early days, Secure Sockets Layer (SSL) is a cryptographic protocol that extends HTTP to authenticate
          internet connections and enable encryption and SSL decryption for data communication over a network. In fact,
          TLS is a direct evolution of SSL and introduced to address security vulnerabilities in the earlier protocol.
          The differences between the two are relatively minor, such as the stronger encryption algorithms and ability
          to work on different ports offered by TLS. The terms are used somewhat interchangeably, and the same
          certificates can be used with both TLS and SSL. Still, all releases of SSL have been deprecated, and most
          modern browsers no longer support the protocol.
        </p>
        <p>
          Ironically, earlier versions of TLS were also affected by dangerous vulnerabilities, which led ultimately to
          TLS 1.2 (i.e. the default version recommended by industry professionals). The majority of known protocol
          vulnerabilities were mitigated in TLS 1.2, but this level of security still was the result of a series of
          patches on top of a flawed design.
        </p>
        <p>
          As a response, TLS 1.3 was drafted from scratch in an effort to cleanly design a modern and secure TLS
          protocol. Five years of testing later, it has finally been approved and is now close to being the default
          Internet security standard.
        </p>
        <ul>
          <li>TLS 1.0 was published as RFC 2246 in 1996</li>
          <li>TLS 1.1 was published as RFC 4346 in 2006</li>
          <li>TLS 1.2 was published as RFC 5246 in 2008</li>
          <li>TLS 1.3 was published as proposed standard in RFC 8446 in 2018.</li>
        </ul>
        <h5>How TLS works</h5>
        <p>
          TLS is a hybrid cryptosystem, meaning that it uses both asymmetric (public key) and symmetric (password/phrase
          based) encryption. This is due to asymmetric cryptography being orders of magnitude slower than its symmetric
          equivalents.
        </p>
        <p>
          Consequently, TLS only employs public keys so that clients and servers can securely exchange a symmetric key.
          This key can then used to encrypt all subsequent communications, avoiding the performance overhead imposed by
          asymmetric encryption.
        </p>
        <p>
          TLS 1.2 supports multiple key exchange algorithms (e.g. RSA, DH, etc.), along with several algorithms (also
          known as ciphers) used to encrypt and decrypt messages. This large amount of alternative options requires
          clients and servers to negotiate, so that all parties use the same TLS parameters. This negotiation is
          standardized in a protocol called handshake.
        </p>
        <h5>Key difference of TLS 1.2 vs TLS 1.3</h5>
        <img src={tlsDiff} alt="tls version difference" />
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};