import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Play with Node – Express, Jade and Mongoose";
  const dateStamp = "20150807";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Recently tried node developing a handy tool for geo-encoding logger. View{" "}
          <a href="http://geolog.hanswang.info" target="_blank" rel="noopener noreferrer">
            demo
          </a>
          . The object of this page is to utilize Google auto-complete tool and GeoEncoding API to log geometry
          coordinates about several locations of interest.
        </p>
        <p>On the page,</p>
        <ol>
          <li>
            <em> User will be able to type on the input box to find the location</em>
          </li>
          <li>
            <em>
              {" "}
              Once location chosen, while it is pinned on the map, its geometry location along with its address
              attributes is logged into our db (Mongo) using ajax
            </em>
          </li>
          <li>
            <em> Page should dynamically change once new record is added</em>
          </li>
          <li>
            <em> User will be able to delete the location from record list</em>
          </li>
          <li>
            <em> Page also should dynamically change once new record is deleted</em>
          </li>
          <li>
            <em> No Same location should be recorded twice</em>
          </li>
        </ol>
        <p>
          If interested, here is{" "}
          <a href="https://github.com/hanswang/geoLoger" target="_blank" rel="noopener noreferrer">
            Source Code
          </a>
        </p>
        <p>
          On the design, I want it to be one-page application. I want to try on NodeJs for the cool syntax. I want to
          utilize Mongo DB since the address components could be extremely dynamic. Here is what I have done.
        </p>
        <ol>
          <li>
            <strong>Due to vulnerability of expressJs in Node, choose PM2 to manage the application</strong>
          </li>
          <li>
            <strong>Use nginx as reverse proxy to handle request to Node</strong>
          </li>
          <li>
            <strong>
              Simple route coding using express, to handle get request on page load, and post request on Ajax call
            </strong>
            <i> Express body-parser to handle json related requests.</i>
          </li>
          <li>
            <strong>
              Use Jade + bootstrap to mock up the page, its really handy to handle all the page mock up using jade
              simplified syntax.
            </strong>
          </li>
          <li>
            <strong>Load Google API to render the map and Auto-complete plugin</strong>
            <i> Since V3, google Javascript plugin no longer requires API key to load the frontend js calls</i>
          </li>
          <li>
            <strong>
              Fire customize event to export location geometry parameter, and capture it to post ajax request
            </strong>
            <i>
              From here on, comparing backend js file &amp; frontend (client-side) js file, I really felt that Node is a
              strongly MVC structured code. Developing backend requires{" "}
              <code>{`pm2 restart <del datetime="2015-08-07T05:13:52+00:00">app</del>`}</code>. Developing frontend is
              change-and-go. This is very much like java developing.
            </i>
          </li>
          <li>
            <strong>Data Related, chosing Mongoose to map the object from db. In schema definition,</strong>
            <ul>
              <li>
                use non-strict <code>{`{strict: false}`}</code> to dynamically add unexpected attributes inside model.
              </li>
              <li>
                apply unique index on combination of latitude &amp; longtitude{" "}
                <code>{`index( { 'latitude' : 1, 'longtitude' : 1 }, {unique: true} )`}</code>&nbsp;to prevent
                duplication
              </li>
              <li>
                add pre filter to update, to change update_at field whenever records changed (
                <em>Somehow, findByIdAndUpdate is not triggering this pre filter.)</em>
              </li>
              <li>update status =&gt; “disable” instead of remove to keep a track of every bit of data input</li>
            </ul>
          </li>
          <li>
            <strong>
              On page HTML Dom change according to user action (adding &amp; deleting), No much of worth mentioning.
            </strong>
          </li>
        </ol>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
