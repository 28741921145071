import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import au1yr from "assets/img/1yr-au-cny.png";
import au5yr from "assets/img/5yr-au-cny.png";
import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "澳元汇率走势分析";
  const dateStamp = "20130305";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          观察澳元近期5年内的走势，在08年到09年之间，澳元有一次惊人跳水。
          <br />
          但紧随其后的就是一次迅速爬升。如下图：
          <br />
          <img src={au5yr} alt="5yr-au-cny" />
        </p>
        <p>
          我至今还深刻记得09年时间里，我忙于申请国外研究生学校，而众多的学校都因为金融危机而无法给学生开出奖学金。
          <br />
          正当我疲于奔命在联系教授时，澳币4.4的汇率使我眼前一亮，而由此踏上了澳洲之路。
        </p>
        <p>
          回归正题，有网上分析认为这次澳币的跳水原因：“
          <em>
            持续了近八年的套息交易在金融市场全部溃败的情况下失去了存在的空间，于是”反噬”的过程开始了。由于市场对未来前景的极度悲观。原油跳水到了30美元，除了黄金任何商品都在急剧下跌。由于很多银行人人自危，贸易融资担保几乎停止，贸易量急跌，衡量世界贸易兴旺的波罗的海干散货指数下跌超过90%。世界贸易将近陷入停滞。那个”美好的循环”转向了！此时澳元在短短的三个月内澳元/人民币从6.61跌到4.25，跌幅高达36%，澳元兑美元汇率跌幅更是高达40%。金融恐慌把澳元打入了冰窖
          </em>
          。”
        </p>
        <p>
          <strong>就是金融恐慌，容易造成澳币的币值波动。</strong>
          <br />
          作为一个以畜牧业，矿业出口为主要输出经济的大国，加工制造业主要靠外包东南亚和中国，高科技和金融服务业主要受美国的影响。总体来说，它是一个对国际贸易依赖较大的国家。所以全球经济的气温都会直接影响澳洲货币的价值。
          <br />
          最直接来说，就是澳洲为了保持贸易顺差，必须及时调整它的内部经济政策以及货币政策，来保证国内就业率和社会稳定。而澳洲为了保持其经济的增长和发展，就需要一方面综合丰富发展本国经济结构，另一方面稳固地适应到当前的世界经济格局中。
        </p>
        <p>
          与此同时，再看澳币的迅速崛起过程，网上分析说：“
          <em>
            在世界各国央行和政府的联手行动下，恐慌情绪慢慢在金融市场上消退，世界主要央行向金融市场保证，货币会充分供应，融资需求会得到有效满足。各主要国家央行利率急剧降低至前所未有地步。各国政府出台大额经济刺激计划。这一切让金融市场缓过劲来，原先冰封的商业票据市场和银行间拆借市场等金融市场开始解冻，进出口企业逐渐可以在银行获得贸易融资，国外银行承兑票据可以在本国银行贴现，世界贸易渐渐得以恢复，在金融血缓慢舒缓后通后世界贸易似乎活了过来。商品开始大幅反弹，囤积美元避险需求慢慢减少，外汇市场开始聚焦未来美国政府的巨额赤字。美元在危机后遭遇了”迟来的”打击。在中国再次钉住美元的汇率政策下，澳元/人民币开始了快速攀升的过程，至今已经上升至6.2，涨幅接近46%，几乎快要达到了跳水前的高度。澳元同时期对其他货币也是大幅反弹。
          </em>
          ”<br />
          这样说明在世界经济局势好的情况下，澳洲凭借自身的<strong>资源优势</strong>
          ，在市场利好的前提下，货物很容易出手。这样早就了澳洲经济的迅速复苏。
        </p>
        <p>
          另外再看一幅数据：
          <br />
          <img src={au1yr} alt="1yr-au-cny" />
          <br />
          澳洲一年的汇率起伏如图所示。一年波动都在6.15以上，在5月底达到最低。
          <br />
          但有分析家认为，2013年是澳币跌的一年。
          <br />
          根据3月5号最新RBA消息：
          <a href="http://www.rba.gov.au/media-releases/2013/mr-13-04.html">
            Statement by Glenn Stevens, Governor: Monetary Policy Decision
          </a>
          <br />
          澳州储备银行将维持利息不变政策，因看其经济形势回暖。
        </p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
