import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Stay Hungry Stay Foolish";
  const dateStamp = "20160901";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Time flies in 2016. It’s almost 4<sup>th</sup> quarter of this year.
        </p>
        <p>
          Looking back, so many things has happened this year, including baby Max, parents arrival &amp; leave, quitting
          DOTA, excessive weight gain and limited exercise including early morning dog walking.
        </p>
        <p>
          Quietly, so many other important aspects in me have also changed. Gold has slipped by before I could realize
          the substantial damage inside.
        </p>
        <p>I need to remind myself again,</p>
        <ol>
          <li>Stay Hungry, stay foolish</li>
          <li>Keep fighter attitude</li>
          <li>Let presure drive instead of crash</li>
        </ol>
        <p></p>
        <p>Technically, maintain a good routine as,</p>
        <ol>
          <li>Keep Side project going</li>
          <li>Constant reading from Hacker News</li>
          <li>Clear objective &amp; promises, and be competent of finishing it</li>
          <li>Keep posting weekly</li>
        </ol>
        <p>
          Life isn’t going any easier as same as this blog/post won’t go anywhere else. Keeping the reminder of the
          future days (drinking chicken soup).
        </p>
        <p>
          <b>Don’t die inside. Keep fighting and thriving.</b>
        </p>
        <p>
          <em>– The end –</em>
        </p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
