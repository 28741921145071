import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Learning notes on Terraform and Terragrunt";
  const dateStamp = "20171103";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Terraform/Terragrunt is a powerful tool to provision cloud resources. This post is to talk about some key
          feature and few tricks about it. Firstly, to describe them in quote,
        </p>
        <p>
          Terraform is a tool for building, changing, and versioning infrastructure safely and efficiently. Terraform
          can manage existing and popular service providers as well as custom in-house solutions.
        </p>
        <p>
          Terragrunt is a thin wrapper for Terraform that provides extra tools for working with multiple Terraform
          modules
        </p>
        <p>
          Both of them are powerful tools and I am supposed to do this post long time ago. Now finally motivated enough
          to pull together a few bits and pieces I have learned along the way by using terraform in practice. Here is
          the list.
        </p>
        <ol>
          <li>
            <h5>Remote State &amp; Locking</h5>
            <div>
              <p>
                This can’t be emphasized enough. Remote state enables trackable resource management and alleviate
                teamwork conflicts. And most importantly, this feature has been extended by terragrunt so that we can do
                inherited structure of config setting, which means Don’t-Repeat-Yourself. Take below for example,
              </p>

              <Highlight language="Terraform">{`terragrunt = {
  remote_state {
    backend = "s3"
    config {
      bucket         = "my-terraform-state"
      key            = "\${path_relative_to_include()}/terraform.tfstate"
      region         = "us-east-1"
      encrypt        = true
      dynamodb_table = "my-lock-table"
    }
  }
}`}</Highlight>
              <p>
                This&nbsp;<code>remote_config</code>&nbsp;setting will support all other backends types by terraform as
                well.
              </p>
              <p>
                And when this file is included when running terragrunt, all belowing terraform settings will be updated.
              </p>

              <Highlight language="Terraform">{`terraform {
  # The configuration for this backend will be filled in by Terragrunt
  backend "s3" {}
}`}</Highlight>
              <p>&nbsp;</p>
            </div>
          </li>
          <li>
            <h5>Modularity is king of re-usable code</h5>
            <div>
              <p>
                This is my favourite feature of terraform. Creating modules means the code can break into multiple
                levels of granularity. And each level of complexity VS repentance can be easily managed at no cost
                (except for calling init again for redeclaring the modules before calling plan). A simple setup would
                looks like below,
              </p>

              <Highlight language="Terraform">{`terragrunt = {
  terraform {
    source = "git::git@github.com:foo/modules.git//app?ref=v0.0.3"
  }
}

instance_count = 3
instance_type = "t2.micro"`}</Highlight>
              <p>
                (Note: the double slash (//) is intentional and required. It’s part of Terraform’s Git syntax for module
                sources. Terraform may display a “Terraform initialized in an empty directory” warning, but you can
                safely ignore it.)
              </p>
              <p>This is only the starting point. Some further thinking may be,</p>
            </div>
            <ol className={classes.secondaryOL}>
              <li>
                Different modules could have different dependencies. Since terraform doesn’t support module level
                dependency. It could be done by terragrunt.
                <Highlight language="Terraform">{`terragrunt = {
  dependencies {
    paths = ["../vpc", "../mysql", "../redis"]
  }
}`}</Highlight>
                &nbsp;
              </li>
              <li>
                Modules managed by terragrunt requires a lot of tfvars file. Maybe for simpler project managing one
                module in terraform which gathers all the pieces for other modules will be easier, which essentially is
                creating one master module on top of other modules. It gives better experience when transfering this
                project to pure terraform. And it much clearer in the way that tfvars files are defined.
              </li>
            </ol>
          </li>
          <li>
            <h5>Loop, if-else syntax with magic “count”</h5>
            <div>
              Essentially its terraform Interpolation of variables. Terraform can support both list and map types. Below
              are some most inspiring examples I have known so far.
            </div>
            <p></p>

            <Highlight language="Terraform">{`# dynamic count
count = "\${length(data.aws_availability_zones.azs.names)}"

# Loop list value
availability_zone = "\${element(var.azs, count.index)}"

# Loop list of map object
instance = "\${element(aws_instance.example.*.id, count.index)}"

# true/false convert 1/0
count = "\${var.create_eip}"
count = "\${var.create_eip ? 1 ： 0}"

# count on data
data "template_file" "user_data_shell" {
  count = "\${var.use_shell_script_user_data}"
  template = &lt;&lt;-EOF
              #!/bin/bash
              run-microservice.sh
              EOF
}`}</Highlight>
            <p>
              However, there is a BIG catch when using the count. DON’T DEPENDS COMPUTED RESOURCES. Since count is sent
              before dynamically computing any resources. So at the stage count is calculated, computed resource/numbers
              won’t be availabe. So you will get below error,
            </p>

            <Highlight language="Terraform">
              {`Error: Error running plan: 1 error(s) occurred:

* module.route_table.aws_route_table_association.connect_gw: aws_route_table_association.connect_gw: value of 'count' cannot be computed`}
            </Highlight>
          </li>
          <li>
            <h5>Connecting gap between terraform &amp; terragrunt</h5>
            <ol className={classes.secondaryOL}>
              <li>
                Including Sequence
                <div>
                  <p>
                    Child block when including will always override the parent block settings if the setting has the
                    same name. However, if the setting has a different name, the two sets will be merged together. And
                    if in this case, the child setting takes higher priority. Two useful functions to mention here,
                  </p>
                  <p>
                    <code>find_in_parent_folder()</code> : This function returns the path to the first&nbsp;
                    <code>terraform.tfvars</code> file it finds in the parent folder above current&nbsp;
                    <code>terraform.tfvars</code> file.
                  </p>
                  <p>
                    <code>path_relative_to_include()</code> : This function returns the relative path between the
                    current&nbsp;<code>terraform.tfvars</code> file and the path specified in its include block. It is
                    typically used in root&nbsp;<code>terraform.tfvars</code> file so each child module store its state
                    file at different key.
                  </p>
                </div>
              </li>
              <li>
                Readable Global VARs
                <div>
                  <p>
                    Some environment variable is visible to both terragrunt and terraform. For example&nbsp;
                    <code>{`export TERRAGRUNT_IAM_ROLE="arn:aws:iam::ACCOUNT_ID:role/ROLE_NAME"`}</code>&nbsp;is
                    equivalent to call terragrant with option{" "}
                    <code>{`--terragrunt-iam-role "arn:aws:iam::ACCOUNT_ID:role/ROLE_NAME"`}</code>. Further example
                    could be,
                  </p>
                  <p>
                    <code>TF_VAR_name</code>
                  </p>
                  <p>
                    <code>TF_CLI_ARGS</code>
                  </p>
                  <p>
                    <code>TF_INPUT</code>
                  </p>
                </div>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          In summary, using terraform is so much a better experience compared to other alternatives. No never-ending
          JSON template file and dodgy (at least IMO) DSL syntax.
        </p>
        <p>Here for FYI useful links,</p>
        <p>
          <a href="https://github.com/gruntwork-io/terragrunt" target="_blank" rel="noopener noreferrer">
            https://github.com/gruntwork-io/terragrunt
          </a>
        </p>
        <p>
          <a href="https://www.terraform.io/docs/providers/aws/index.html" target="_blank" rel="noopener noreferrer">
            https://www.terraform.io/docs/providers/aws/index.html
          </a>
        </p>
        <p>
          <a
            href="https://www.terraform.io/docs/configuration/interpolation.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.terraform.io/docs/configuration/interpolation.html
          </a>
        </p>
        <p>
          <a
            href="https://blog.gruntwork.io/terraform-tips-tricks-loops-if-statements-and-gotchas-f739bbae55f9"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://blog.gruntwork.io/terraform-tips-tricks-loops-if-statements-and-gotchas-f739bbae55f9
          </a>
        </p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
