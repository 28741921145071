import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "The seven rules of a great git commit message";
  const dateStamp = "20150914";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <ol>
          <li>Separate subject from body with a blank line</li>
          <li>Limit the subject line to 50 characters</li>
          <li>Capitalize the subject line</li>
          <li>&gt;Do not end the subject line with a period</li>
          <li>Use the imperative mood in the subject line</li>
          <li>Wrap the body at 72 characters</li>
          <li>
            Use the body to explain <em>what</em> and <em>why</em> vs. <em>how</em>
          </li>
        </ol>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
