import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionBasics(props) {
  const classes = useStyles();

  const dateStamp = "20120904";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>My static photo album hosted on S3</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>Hooray!</p>
        <p>I just created my static photo album photo.hanswang.info</p>
        <p>It is using Amazon Web Service S3, serving all content as static page.</p>
        <p>
          Since its whole purpose is an photo album for family, S3 server is perfect for serving this content as CDN.
        </p>
        <p>
          See the official reference from{" "}
          <a href="http://docs.amazonwebservices.com/AmazonS3/latest/dev/Welcome.html">AWS S3 develop Guide</a>.
        </p>
        <p>Here is how I did it.</p>
        <ol>
          <li>
            Create a s3 bucket named “photo.hanswang.info”, and make it public. By setting the policy, and endpoint url.
            Detail see AWS S3 doc ref
          </li>
          <li>
            Change DNS record for <i>photo.hanswang.info</i> to be CNAME to bucket public url
          </li>
          <li>
            Build the index.html inside the bucket root dir, and all other associated resources, such as sprited icons,
            aggregated css, and most importantly the relative urls for photos
          </li>
          <li>Deploy and integrate the index.html page with jQuery Masonry. Done and Enjoy!</li>
        </ol>
      </div>
    </div>
  );
}

SectionBasics.propTypes = {
  link: PropTypes.string,
};
