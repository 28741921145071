import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Nginx Basic Auth";
  const dateStamp = "20120918";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>Scenario: Password protected certain area of sub-dir of the web server content.</p>
        <p>
          See Quote:{" "}
          <a href="http://wiki.nginx.org/HttpAuthBasicModule" rel="nofollow">
            Basic HTTP Auth nginx
          </a>
          <br />
          Example:
        </p>
        <Highlight language="Nginx config">
          {`location  /  {
  auth_basic            "Restricted";
  auth_basic_user_file  htpasswd;
}`}
        </Highlight>
        <p>
          htppasswd is the file store all your username and password, and is relative to directory of nginx
          configuration file nginx.conf.
        </p>
        <p>To generate an .htpasswd file in linux, most common solution would be using openssl</p>
        <p>4 algorithms can be chosen, (crypt, Apache MD5, MD5 and Salted SHA-1)</p>
        <Highlight language="Bash">
          {`# this example uses crypt encryption
printf "(username):$(openssl passwd -crypt actual_passwd)\\n" >> .htpasswd

# this example uses apr1 (Apache MD5) encryption
printf "(username):$(openssl passwd -apr1 actual_passwd)\\n" >> .htpasswd

# this example uses MD5 encryption
printf "(username):$(openssl passwd -1 actual_passwd)\\n" >> .htpasswd

# This example uses SSHA (Salted SHA-1) encryption
echo "(username):{SSHA}$(echo -n 'actual_passwdSALT' | openssl dgst -binary -sha1 | sed 's/$/MYSALT/' | base64)" >> .htpasswd`}
        </Highlight>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
