import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Enable Xdebug on PHP development";
  const dateStamp = "20150928";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>Xdebug is crucially important for PHP stack trace debugging.</p>
        <p>Here is what I have done to get started.</p>
        <ol>
          <li>
            Install Xdebug in ubuntu,
            <Highlight language="Bash">{`sudo apt-get install php5-xdebug`}</Highlight>
          </li>
          <li>
            Modify php.ini to activate Xdebug
            <Highlight language="ini">
              {`; file of /etc/php5/fpm/php.ini
[xdebug]
zend_extension="/usr/lib/php5/20121212/xdebug.so"
xdebug.remote_enable=1
xdebug.remote_handler=dbgp xdebug.remote_mode=req
xdebug.remote_host=127.0.0.1 xdebug.remote_port=9000`}
            </Highlight>
          </li>
          <li>
            Restart the webserver
            <Highlight language="Bash">{`sudo service nginx restart`}</Highlight>
          </li>
        </ol>
        <p>On a separate note, for an important feature, now we can call the function,</p>
        <Highlight language="PHP">
          {`// stacktrace debug till the calling point
var_dump(xdebug_get_function_stack());

// stacktrace printing message till the calling point
xdebug_print_function_stack( 'Your own message' );`}
        </Highlight>
        <p>Thess functions will find out all the trace back for current calls.</p>
        <p>First one will display every parameters within it. Extremely useful for debugging through large codebase.</p>
        Or follow the instruction on Xdebug. (Cited from{" "}
        <a href="http://xdebug.org/docs/install" target="_blank" rel="noopener noreferrer">
          Xdebug
        </a>
        )<p></p>
        <blockquote>
          <p>Instructions</p>
          <p>
            Download xdebug-2.3.3.tgz
            <br />
            Unpack the downloaded file with tar -xvzf xdebug-2.3.3.tgz
            <br />
            Run: cd xdebug-2.3.3
            <br />
            Run: phpize (See the FAQ if you don’t have phpize.
          </p>
          <p>As part of its output it should show:</p>
          <p>
            Configuring for:
            <br />
            …<br />
            Zend Module Api No: 20121212
            <br />
            Zend Extension Api No: 220121212
            <br />
            If it does not, you are using the wrong phpize. Please follow this FAQ entry and skip the next step.
          </p>
          <p>
            Run: ./configure
            <br />
            Run: make
            <br />
            Run: cp modules/xdebug.so /usr/lib/php5/20121212
            <br />
            Update /etc/php5/fpm/php.ini and change the line
            <br />
            zend_extension = /usr/lib/php5/20121212/xdebug.so
            <br />
            Restart the webserver
          </p>
        </blockquote>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
