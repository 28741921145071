import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Difference between Docker And Vagrant";
  const dateStamp = "20160909";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          Very good read about{" "}
          <a
            href="http://stackoverflow.com/questions/16647069/should-i-use-vagrant-or-docker-for-creating-an-isolated-environment"
            rel="noopener noreferrer"
            target="_blank"
          >
            Difference between Docker and Vagrant
          </a>
        </p>
        <p>I see docker as application container while vagrant as vitual-machine management tools.</p>
        <p>So in terms of performance of booting up application, docker is faster than vagrant.</p>
        <p>To be continued…</p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
