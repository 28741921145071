import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "First dive into Talend Open Studio";
  const dateStamp = "20170310";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <h5>Background Story</h5>
        <p>
          Back in the days, I was involved in the development of an automation flow which required a language called
          BPEL (Business Process Execution Language). It’s the type of development when the GUI is presented with
          different components. And what is needed for developing flow is drag &amp; drop on canvas.
        </p>
        <p>
          To be honest, this kind of development looks fresh at the start. But later on, more issues were found during
          further interaction with these development tools so we have to be forced back to look into the code the trace
          down the error, since under the “fancy” cover the GUI tools, it’s auto-generating code mechanism which
          essentially still generates a fairly complex piece of java code.
        </p>

        <p>
          Coming back to the topic of Talend of this post, it’s related to this particular previous experience because
          they both are the same type of development mode that works on interactive GUI to design and orchestrate
          workflows. And perhaps not surprisingly, they are generating JAVA code at the back.
        </p>
        <p>So here is what I have experienced with Talend Open Studio.</p>
        <h5> The Good </h5>
        <ol>
          <li>
            It’s essentially JAVA! The outcome of code generated is essentially java. Having said that, this means if
            there is a syntax error, or there is a misunderstanding of how code works, we can always look into the code
            part side right-side of “Designer” panel of canvas and find out the exact reason.
          </li>
          <li>
            The orchestration flow is clear and easy to read from start given a canvas based design flow illustration,
            which might be much clean and easy to read than the code. (Opinionated!)
          </li>
          <li>
            Many featured components to work on instead of implementing them from scratch, e.g. FTP connection, File
            read and listing and writing, AWS S3 interaction, and data flow processor such as tMap, tNormalize, tUnite,
            tJavaFlex, tJavaRow.
          </li>
        </ol>
        <h5> The Bad </h5>
        <ol>
          <li>
            It’s essentially JAVA! The runtime Env is slow due to JVM and requires extra compilation beforehand. Digging
            through java runtime error is not fun. Code generated is more and more complex when putting more components
            into the canvas which eventually takes up all the resources.
          </li>
          <li>
            The context is double edged sword. It provides a clean and neat way of managing passing variables between
            jobs. But when lack of proper managing context in a much re-usable and clean way (similar to the concept of
            “eliminating global variables” in writing other codes), the number of them and maintenance overhead could
            easy be blown up.
          </li>
          <li>
            Some learning curve is expected when dealing with components like tJavaFlex. It may not work as originally
            expected when first come to use. And documentation about these components are just terrible all over the
            internet.
          </li>
        </ol>
        <h5> Learnings </h5>
        <ol>
          <li>Get faster machines with bigger RAM</li>
          <li>Managing context with proper plan beforehand</li>
          <li>
            A good way of learning Talend is always trying to use it. It may take some time at the start, but it will
            always pay back at a later stage. Especially when one particular component is not familiar, put in tons of
            “System.out.println” will definitely help in understanding the priorities and flow.
          </li>
          <li>
            Putting in a lot of tWarn as placeholder and logging messages helps understand the application as well as
            helps program stand in a better position of self-organizing.
          </li>
          <li>
            Use tRunJob wisely since each job is representing a standalone process. Having said that, this means each
            job can be run independently and get valid result based on the ENV and inputs.
          </li>
          <li>
            Distinguish the concept of flow and row. Flow mainly focus on process orchestration while row represents the
            data stream. Having said that, there are many cases when we need to convert row data into different flow and
            vice versa. Think wisely. A lot of options here.
          </li>
          <li>Linkage between components such as “main”, “iterate” will pass the data row along the flow.</li>
          <li>
            Linkage between components such as “onSubJobOK”, “onSubJobError”, “onComponentOk”, “run If” will do the
            trigger once the current component and condition are met.
          </li>
          <li>
            Passing values between child job to parent job. Context and bufferOutput are commonly used. Be cautious
            about global variables.
          </li>
          <li>“CHILD_RETURN_CODE” is useful tool to reflex tRunJob running status.</li>
          <li>
            Useful tip as <code>{`<ctrl>+<space>`}</code> to trigger lookup for all global variables available at given
            point.
          </li>
          <li>
            All exceptions should be handled properly, otherwise, it will be escalated to the top till the process get
            killed. Same rule as Java.
          </li>
        </ol>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
