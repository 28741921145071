import React from "react";
import Carousel from "react-slick";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import classNames from "classnames";

import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import fileWord from "assets/files/Resume-20221119.docx";
import filePage from "assets/files/Resume-20221119.pages";
import filePDF from "assets/files/Resume-20221119.pdf";
import certTodo from "assets/img/cert-todo.png";
import cert1 from "assets/img/cert1.png";
import cert2 from "assets/img/cert2.png";
import cert3 from "assets/img/cert3.png";
import cert4 from "assets/img/cert4.png";
import cert5 from "assets/img/cert5.png";
import cert6 from "assets/img/cert6.png";
import cert7 from "assets/img/cert7.png";
import cert8 from "assets/img/cert8.png";
import profile from "assets/img/hans-profile-2019.jpg";
import bgImage from "assets/img/profile-bg.jpg";
import styles from "assets/jss/hwblog/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(classes.imgRaised, classes.imgRoundedCircle, classes.imgFluid);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  return (
    <div>
      <Header
        color="transparent"
        brand="About Hans Wang @ Sydney"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={bgImage} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div>
                  <img src={profile} alt="..." className={imageClasses} />
                </div>
                <div className={classes.name}>
                  <h3 className={classes.title}>Hans Wang</h3>
                  <Typography variant="subtitle1" component="div" className={classes.helperText}>
                    First and foremost, I got an{" "}
                    <Typography
                      variant="body"
                      component="a"
                      className={classes.highlightText}
                      href={filePDF}
                      target="_blank"
                    >
                      Resume
                    </Typography>{" "}
                    publicly available.
                  </Typography>
                  <Divider />
                  <Button justIcon link className={classes.hoverHighlight} href={fileWord} target="_blank">
                    <i className={"fas fa-file-word"} />
                  </Button>
                  <Button justIcon link className={classes.hoverHighlight} href={filePage} target="_blank">
                    <i className={"fas fa-file-powerpoint"} />
                  </Button>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classes.description}>
            <Typography variant="body1" component="p">
              Senior Software Engineer who is humble and eager to learn. Innovative, motivated, detail focused and
              results oriented. Well-experienced with multiple cloud platforms and on-cloud architecture design.
              Expertise in many evolving technologies, including frontend, server-less, machine learning, AI, big data
              and Security. Enjoying architecture design, advocating full automation, code refactor and stats-proven
              system optimisation. Vastly exposed to many frameworks (React/Vue/ Express/SpringBoot/Django/ Laravel),
              languages, DevOps tools (Bamboo/Jenkins/ Docker/ansible/Splunk/ELK) and various RDBMS & NoSQL. Also
              committed to multi-cloud managing strategy (terraform, AWS, GCP, Azure)
            </Typography>
            <List
              dense
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  My Core Skillset
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemText primary="AWS" />
              </ListItem>
              <List component="div" disablePadding dense>
                <ListItem className={classes.nested}>
                  <ListItemText primary="EC2/S3" />
                </ListItem>

                <ListItem className={classes.nested}>
                  <ListItemText primary="ELB/ALB" />
                </ListItem>

                <ListItem className={classes.nested}>
                  <ListItemText primary="CloudFront" />
                </ListItem>

                <ListItem className={classes.nested}>
                  <ListItemText primary="CloudFormation" />
                </ListItem>

                <ListItem className={classes.nested}>
                  <ListItemText primary="Beanstalk" />
                </ListItem>

                <ListItem className={classes.nested}>
                  <ListItemText primary="…" />
                </ListItem>
              </List>
              <ListItem>
                <ListItemText primary="Python - Django/Flask" />
              </ListItem>
              <ListItem>
                <ListItemText primary="React - Redux/Thunk" />
              </ListItem>
              <ListItem>
                <ListItemText primary="PHP – Most popular frameworks (Laravel, Yii, Zend" />
              </ListItem>
              <ListItem>
                <ListItemText primary="MySQL/PostgreSQL" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Mongo/NoSQL" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Javascript vanilla" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Material Design" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Bootstrap CSS" />
              </ListItem>
              <ListItem>
                <ListItemText primary="HTML/HTML5" />
              </ListItem>
              <ListItem>
                <ListItemText primary="git" />
              </ListItem>
              <ListItem>
                <ListItemText primary="SEO" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Perl" />
              </ListItem>
              <ListItem>
                <ListItemText primary="C++" />
              </ListItem>
            </List>
            <Divider className={classes.pageDivider} />
            <Typography variant="body1" component="p">
              With a vast range of interests in computer engineering development, I am seasoned code in the field of Web
              development covering frontend complex SPA build, following to backend API design all the way till database
              optimization. I am always trying to keep learning about cut-edge technology and always excited about any
              opportunities/challenges coming with it.
            </Typography>
            <Divider className={classes.pageDivider} />
            <Typography variant="subtitle1" component="p" className={classes.focused}>
              I can be reached by below means,
            </Typography>
            <Typography
              variant="body1"
              component="a"
              className={classes.hoverHighlight}
              href="mailto:hanswang2012@gmail.com?Subject=Contact%20from%20HW-info"
              target="_top"
            >
              hanswang2012@gmail.com
            </Typography>
            <Typography variant="body1" component="p">
              Or checkout my{" "}
              <Typography
                variant="body1"
                component="a"
                className={classes.hoverHighlight}
                href="https://au.linkedin.com/pub/hans-wang/4a/643/788"
                target="_blank"
                rel="noopener"
              >
                Linkedin Profile
              </Typography>
            </Typography>
            <Divider className={classes.pageDivider} />
            <Typography variant="subtitle1" component="p" className={classes.focused}>
              Certification Town Hall
            </Typography>
          </div>
          <div className={classes.carouselBox}>
            <Carousel {...settings}>
              <div>
                <img src={cert8} alt="AWS devops cert" className="slick-image" />
                <div className="slick-caption">
                  <div className={classes.flexCenter}>
                    <i className="slick-icons fab fa-aws" />{" "}
                    AWS Certified DevOps - Professional
                  </div>
                </div>
              </div>
              <div>
                <img src={cert7} alt="AWS cert" className="slick-image" />
                <div className="slick-caption">
                  <div className={classes.flexCenter}>
                    <i className="slick-icons fab fa-aws" />{" "}
                    AWS Certified Database - Specialty
                  </div>
                </div>
              </div>
              <div>
                <img src={cert6} alt="AWS cert" className="slick-image" />
                <div className="slick-caption">
                  <div className={classes.flexCenter}>
                    <i className="slick-icons fab fa-aws" />{" "}
                    AWS Certified Machine Learning - Specialty
                  </div>
                </div>
              </div>
              <div>
                <img src={cert5} alt="AWS cert" className="slick-image" />
                <div className="slick-caption">
                  <div className={classes.flexCenter}>
                    <i className="slick-icons fab fa-aws" />{" "}
                    AWS Certified Data Analytics - Specialty
                  </div>
                </div>
              </div>
              <div>
                <img src={cert4} alt="AWS cert" className="slick-image" />
                <div className="slick-caption">
                  <div className={classes.flexCenter}>
                    <i className="slick-icons fab fa-aws" />{" "}
                    AWS Certified Security - Specialty
                  </div>
                </div>
              </div>
              <div>
                <img src={cert1} alt="AWS cert" className="slick-image" />
                <div className="slick-caption">
                  <div className={classes.flexCenter}>
                    <i className="slick-icons fab fa-aws" />{" "}
                    AWS Certified Developer - Associate
                  </div>
                </div>
              </div>
              <div>
                <img src={cert2} alt="AWS renew cert" className="slick-image" />
                <div className="slick-caption">
                  <div className={classes.flexCenter}>
                    <i className="slick-icons fab fa-aws" />{" "}
                    AWS Certified Developer - Associate Renew
                  </div>
                </div>
              </div>
              <div>
                <img src={cert3} alt="AWS SAA cert" className="slick-image" />
                <div className="slick-caption">
                  <div className={classes.flexCenter}>
                    <i className="slick-icons fab fa-aws" />{" "}
                    AWS Certified Solution Artitect - Associate
                  </div>
                </div>
              </div>
              <div>
                <img src={certTodo} alt="Future" className="slick-image" />
                <div className="slick-caption">
                  <div className={classes.flexCenter}>
                    <i className="slick-icons fas fa-award" />{" "}
                    Something better yet to come
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
          <Divider className={classes.pageDivider} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
