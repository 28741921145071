import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "php – SOAP-ERROR: Encoding: Object has no property";
  const dateStamp = "20150317";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          SOAP is a robust web service schema. What I encountered today is the SOAP definition *.wsdl file won’t compile
          with variables passed in. Leaving me with a big blank screen of{" "}
          {`"SOAP-ERROR: Encoding: Object has no property of 'xxx'"`}.
        </p>
        <p>
          After hours of dark searching. The problem relies on the variable structure does not match with the object
          passed on.
        </p>
        <p>
          When this happens, change the nested object structure to <code>xsd:string</code>, and json_encode whatever
          passed from script.
        </p>
        <p>After examine the json string of returned object, I found as follow structure,</p>
        <Highlight language="xml">
          {`<xsd:sequence>                                                                                                                                                      
    <xsd:element name="objectName" type="demo:ObjectType" maxOccurs="unbounded"/>                                                              
</xsd:sequence>`}
        </Highlight>
        <p>Will have the “upper” layer of object. So in the end the object need to be transferred from,</p>
        <Highlight language="PHP">
          {`$demo[] = array('objectName' => $object);`}
        </Highlight>
        <p>To:</p>
        <Highlight language="PHP">
          {`$demo['objectName'][] = $object;`}
        </Highlight>
        <p>Then all works.</p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
