import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Google search trend";
  const dateStamp = "20140410";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <h5>The top trend going on in google at this moment:</h5>
        <p>
          <iframe
            frameBorder="0"
            title="google trend table"
            scrolling="no"
            width="650"
            height="400"
            src="//trends.google.com.au/trends/embed/dailytrends?forceMobileMode=false&isPreviewMode=true&hl=enUS&geo=AU"
          ></iframe>
        </p>
        <p>
          <iframe
            height="400"
            width="650"
            frameBorder="0"
            title="google trend grid"
            src="//hawttrends.appspot.com/?hl=en_AU&r=5&c=5"
          ></iframe>
        </p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
