import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/hwblog/views/landingPageSections/configStyle.js";

const useStyles = makeStyles(styles);

export default function P05MacBashConfig(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer className={classes.snippet}>
        <GridItem xs={12}>
          <h2 className={classes.title}>
            <Link to={props.link}>Mac Terminal Config</Link>
          </h2>
          <h5 className={classes.description}>Mac Terminal config Written updated with zsh</h5>
          <h5 className={classes.description}>
            Firstly download Solarized Theme
          </h5>
          <Highlight language="Bash">
            {`$ git clone git@github.com:tomislav/osx-terminal.app-colors-solarized.git`}
          </Highlight>
          <h5 className={classes.description}>
            Secondly download Oh-My-Zsh plugin
          </h5>
          <Highlight language="Bash">
            {`$ git clone git@github.com:ohmyzsh/ohmyzsh.git`}
          </Highlight>
          <h5 className={classes.description}>
            Add below EXPORT to end of ~/.zshrc
          </h5>
          <Highlight language="Bash">
            {`export PATH="/Users/hansw/go/bin:$PATH"

export NVM_DIR="$HOME/.nvm"
[ -s "/usr/local/opt/nvm/nvm.sh" ] && . "/usr/local/opt/nvm/nvm.sh"  # This loads nvm`}
          </Highlight>
          <h6 className={classes.description}><i>Previously in ~/.bash_profile</i></h6>
          <Highlight language="Bash">
            {`export CLICOLOR=1
 
alias ls='ls -GF'
alias ll='ls -lsah'
  
if [ -f $(brew --prefix)/etc/bash_completion ]; then
  . $(brew --prefix)/etc/bash_completion
fi
  
export PS1="\\e[0;33m\\]\\t\\[\\e[m\\] 🖖🏼  \\e[0;36m\\]\\w\\[\\e[m\\]:\\n $ "

export BASH_SILENCE_DEPRECATION_WARNING=1
`}
          </Highlight>
          <h5 className={classes.description}>
            As you probably noticed, we need the bash-completion from brew by following,
          </h5>
          <Highlight language="Bash">{`$ brew install bash-completion`}</Highlight>
          <h5 className={classes.description}>
            And furthermore, to complete the outlook, we need the color scheme from below repo.
          </h5>
          <Highlight language="Bash">
            {`$ git clone git@github.com:tomislav/osx-terminal.app-colors-solarized.git`}
          </Highlight>
        </GridItem>
      </GridContainer>
    </div>
  );
}

P05MacBashConfig.propTypes = {
  link: PropTypes.string,
};
