import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";
import Quote from "components/Typography/Quote.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "The days are long but the decades are short";
  const dateStamp = "20150429";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <Quote
          text="Quoted from Hacker News, Original link: http://blog.samaltman.com/the-days-are-long-but-the-decades-are-short"
          author="Sam Altman"
        />
        <p>
          <i>
            I turned 30 last week and a friend asked me if I’d figured out any life advice in the past decade worth
            passing on. &nbsp;I’m somewhat hesitant to publish this because I think these lists usually seem hollow, but
            here is a cleaned up&nbsp;version of my answer:
          </i>
        </p>
        <p>
          1)&nbsp;Never put your family, friends, or significant other low on your priority list.&nbsp; Prefer a handful
          of truly close friends to a hundred acquaintances.&nbsp; Don’t lose touch with old friends.&nbsp; Occasionally
          stay up until the sun rises talking to people.&nbsp; Have parties.
        </p>
        <p>
          2)&nbsp;Life is not a dress rehearsal—this is probably it.&nbsp; Make it count.&nbsp; Time is extremely
          limited and goes by fast.&nbsp; Do what makes you happy and fulfilled—few people get remembered hundreds of
          years after they die anyway.&nbsp; Don’t do stuff that doesn’t make you happy (this happens most often when
          other people want you to do something).&nbsp; Don’t spend time trying to maintain relationships with people
          you don’t like, and cut negative people out of your life.&nbsp; Negativity is really bad.&nbsp; Don’t let
          yourself make excuses for not doing the things you want to do.
        </p>
        <p>
          3)&nbsp;How to succeed: pick the right thing to do (this is critical and usually ignored), focus, believe in
          yourself (especially when others tell you it’s not going to work), develop personal connections with people
          that will help you, learn to identify talented people, and work hard.&nbsp; It’s hard to identify what to work
          on because original thought is hard.
        </p>
        <p>
          4)&nbsp;On work: it’s difficult to do a great job on work you don’t care about.&nbsp; And it’s hard to be
          totally happy/fulfilled in life if you don’t like what you do for your work.&nbsp; Work very hard—a surprising
          number of people will be offended that you choose to work hard—but not so hard that the rest of your life
          passes you by.&nbsp; Aim to be the best in the world at whatever you do professionally.&nbsp; Even if you
          miss, you’ll probably end up in a pretty good place.&nbsp; Figure out your own productivity system—don’t waste
          time being unorganized, working at suboptimal times, etc.&nbsp; Don’t be afraid to take some career risks,
          especially early on.&nbsp; Most people pick their career fairly randomly—really think hard about what you
          like, what fields are going to be successful, and try to talk to people in those fields.
        </p>
        <p>
          5)&nbsp;On money: Whether or not money can buy happiness, it can buy freedom, and that’s a big deal.&nbsp;
          Also, lack of money is very stressful.&nbsp; In almost all ways, having enough money so that you don’t stress
          about paying rent does more to change your wellbeing than having enough money to buy your own jet.&nbsp;
          Making money is often more fun than spending it, though I personally have never regretted money I’ve spent on
          friends, new experiences, saving time, travel, and causes I believe in.
        </p>
        <p>
          6)&nbsp;Talk to people more.&nbsp; Read more long content and less tweets.&nbsp; Watch less TV.&nbsp; Spend
          less time on the Internet.
        </p>
        <p>7)&nbsp;Don’t waste time.&nbsp; Most people waste most of their time, especially in business.</p>
        <p>
          8)&nbsp;Don’t let yourself get pushed around.&nbsp; As Paul Graham once said to me, “People can become
          formidable, but it’s hard to predict who”.&nbsp; (There is a big difference between confident and
          arrogant.&nbsp; Aim for the former, obviously.)
        </p>
        <p>9)&nbsp;Have clear goals for yourself every day, every year, and every decade.</p>
        <p>
          10)&nbsp;However, as valuable as planning is, if a great opportunity comes along you should take it.&nbsp;
          Don’t be afraid to do something slightly reckless.&nbsp; One of the benefits of working hard is that good
          opportunities will come along, but it’s still up to you to jump on them when they do.
        </p>
        <p>
          11)&nbsp;Go out of your way to be around smart, interesting, ambitious people.&nbsp; Work for them and hire
          them (in fact, one of the most satisfying parts of work is forging deep relationships with really good
          people).&nbsp; Try to spend time with people who are either among the best in the world at what they do or
          extremely promising but totally unknown.&nbsp; It really is true that you become an average of the people you
          spend the most time with.
        </p>
        <p>
          12)&nbsp;Minimize your own cognitive load from distracting things that don’t really matter.&nbsp; It’s hard to
          overstate how important this is, and how bad most people are at it.&nbsp; Get rid of distractions in your
          life.&nbsp; Develop very strong ways to avoid letting crap you don’t like doing pile up and take your mental
          cycles, especially in your work life.
        </p>
        <p>13)&nbsp;Keep your personal burn rate low.&nbsp; This alone will give you a lot of opportunities in life.</p>
        <p>14)&nbsp;Summers are the best.</p>
        <p>
          15)&nbsp;Don’t worry so much.&nbsp; Things in life are rarely as risky as they seem. &nbsp;Most people are too
          risk-averse, and so most advice is biased too much towards conservative paths.
        </p>
        <p>16)&nbsp;Ask for what you want.</p>
        <p>
          17)&nbsp;If you think you’re going to regret not doing something, you should probably do it.&nbsp; Regret is
          the worst, and most people regret far more things they didn’t do than things they did do.&nbsp; When in doubt,
          kiss the boy/girl.
        </p>
        <p>18)&nbsp;Exercise.&nbsp; Eat well.&nbsp; Sleep.&nbsp; Get out into nature with some regularity.</p>
        <p>
          19)&nbsp;Go out of your way to help people.&nbsp; Few things in life are as satisfying.&nbsp; Be nice to
          strangers.&nbsp; Be nice even when it doesn’t matter.
        </p>
        <p>
          20) Youth&nbsp;is a really great thing.&nbsp; Don’t waste it.&nbsp; In fact, in your 20s, I think it’s ok to
          take a “Give me financial discipline, but not just yet” attitude.&nbsp; All the money in the world will never
          get back time that passed you by.
        </p>
        <p>21)&nbsp;Tell your parents you love them more often.&nbsp; Go home and visit as often as you can.</p>
        <p>22)&nbsp;This too shall pass.</p>
        <p>23)&nbsp;Learn voraciously.</p>
        <p>
          24)&nbsp;Do new things often.&nbsp; This seems to be really important.&nbsp; Not only does doing new things
          seem to slow down the perception of time, increase happiness, and keep life interesting, but it seems to
          prevent people from calcifying in the ways that they think.&nbsp; Aim to do something big, new, and risky
          every year in your personal and professional life.
        </p>
        <p>
          25)&nbsp;Remember how intensely you loved your boyfriend/girlfriend when you were a teenager?&nbsp; Love
          him/her that intensely now.&nbsp; Remember how excited and happy you got about stuff as a kid?&nbsp; Get that
          excited and happy now.
        </p>
        <p>26)&nbsp;Don’t screw people and don’t burn bridges.&nbsp; Pick your battles carefully.</p>
        <p>27)&nbsp;Forgive people.</p>
        <p>28)&nbsp;Don’t chase status.&nbsp; Status without substance doesn’t work for long and is unfulfilling.</p>
        <p>29)&nbsp;Most things are ok in moderation.&nbsp; Almost nothing is ok in extreme amounts.</p>
        <p>
          30)&nbsp;Existential angst is part of life.&nbsp; It is particularly noticeable around major life events or
          just after major career milestones.&nbsp; It seems to particularly affect smart, ambitious people.&nbsp; I
          think one of the reasons some people work so hard is so they don’t have to spend too much time thinking about
          this.&nbsp; Nothing is wrong with you for feeling this way; you are not alone.
        </p>
        <p>
          31)&nbsp;Be grateful and keep problems in perspective.&nbsp; Don’t complain too much.&nbsp; Don’t hate other
          people’s success (but remember that some people will hate your success, and you have to learn to ignore it).
        </p>
        <p>32)&nbsp;Be a doer, not a talker.</p>
        <p>
          33)&nbsp;Given enough time, it is possible to adjust to almost anything, good or bad.&nbsp; Humans are
          remarkable at this.
        </p>
        <p>34)&nbsp;Think for a few seconds before you act.&nbsp; Think for a few minutes if you’re angry.</p>
        <p>
          35)&nbsp;Don’t judge other people too quickly.&nbsp; You never know their whole story and why they did or
          didn’t do something.&nbsp; Be empathetic.
        </p>
        <p>36)&nbsp;The days are long but the decades are short.</p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
