import React from "react";
import Highlight from "react-highlight.js";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/hwblog/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function Article(props) {
  const classes = useStyles();

  const title = "Laravel Socialite integration with composer update (and autoload)";
  const dateStamp = "20150305";
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <h2 className={classes.title}>
          <Link to={props.link}>{title}</Link>
        </h2>
        <Muted>Posted on: {moment(dateStamp).format("MMM Do, YYYY")}</Muted>
        <p>
          After searching in the darkness for several hours.
          <br />
          I finally cleared out the relation between composer update and laravel autoload functionality.
          <br />
          And finally laravel Socialite works like a charm.
        </p>
        <p>
          To start with, we have the “Official Doc” from Laravel doc 5.0, claiming its a painless integration for social
          auth in laravel. For dummy like me, I want to know why after registering facade by entering 2 lines in
          config/app.php would import a huge lib from github.
          <br />
          Of course the code would not be imported “on air” every time, and certainly I haven’t download the src code to
          my repos yet.
        </p>
        <p>
          So by looking at the code, “<code>Illuminate\Cache</code>” are just namespaces.
          <br />
          While socialite using “<code>Laravel\Socialite</code>” as its namespace.
          <br />
          So clearly from namespace to find right path doesn’t work here.
        </p>
        <p>
          Then back to <code>composer.json</code> changes. and along with another file <code>composer.lock</code>.
        </p>
        <p>
          So the code must comes from here.
          <br />
          Looking at this useful{" "}
          <a href="http://www.developed.be/2014/08/29/composer-dump-autoload-laravel/" target="_blank" rel="noopener noreferrer">
            link
          </a>
          , it described <code>composer install</code>, <code>composer update</code> and{" "}
          <code>composer dump-autoload</code> pretty well.
          <br />
          So I may site in here:
        </p>
        <blockquote>
          <ul>
            <li>
              <strong>composer install</strong> installs the vendor packages according to composer.lock (or creates
              composer.lock if not present),
            </li>
            <li>
              <strong>composer update</strong> always regenerates composer.lock and installs the lastest versions of
              available packages based on composer.json
            </li>
            <li>
              <strong>composer dump-autoload</strong> won’t download a thing. It just regenerates the list of all
              classes that need to be included in the project (autoload_classmap.php). Ideal for when you have a new
              class inside your project. (composer dump-autoload -o for better loading performance – unchecked)
            </li>
          </ul>
        </blockquote>
        <p>
          So this must be the solution.
          <br />
          Then two line of code,
        </p>
        <Highlight language="Bash">
          {`cd <laravel dir> && composer update
composer dump-autoload`}
        </Highlight>
        <p>
          Whoola, it worked.
          <br />
          In code,{" "}
        </p>

        <Highlight language="PHP">
          {`Socialize::with('github')->redirect();`}
        </Highlight>
        <p>will work without any FATAL.</p>
      </div>
    </div>
  );
}

Article.propTypes = {
  link: PropTypes.string,
};
