import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { Route, Router, Switch } from "react-router-dom";

import { createBrowserHistory } from "history";

import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import TodosPage from "views/Todos/Todos.js";

import "assets/css/blog.css";

var hist = createBrowserHistory();
ReactGA.initialize("UA-34346647-1");
ReactGA.pageview("/");

hist.listen((location) => {
  ReactGA.pageview(location.pathname + location.search);
});

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/config" component={LandingPage} />
      <Route path="/profile" component={ProfilePage} />
      <Route path="/todos" component={TodosPage} />
      <Route path="/" component={Components} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
